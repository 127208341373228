import { Box } from '@mui/material';
import { Thread, useGetThreadsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  pickThreadUserProps,
  ThreadItem,
  ThreadParams,
  ThreadSkeletonRows,
  useThreads,
} from '@schooly/components/threads';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { FC, useMemo } from 'react';

const SKELETON_COUNT = 10;

export const MessageThreadList: FC<ThreadParams> = ({ schoolId, rootEntityType, rootEntityId }) => {
  const { currentUser } = useAuth();
  const { connectedUser, addSpectator, connectUser, selectThread, createThreadChannel } =
    useThreads();

  const {
    data: threads,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetThreadsQuery(
    {
      school_id: schoolId,
      root_entity_type: rootEntityType,
      root_entity_id: rootEntityId,
    },
    { refetchOnMount: 'always' },
  );

  const entities = useMemo(() => threads?.pages.flatMap((page) => page.results) ?? [], [threads]);
  const loaderRef = useInfiniteScroll(isFetching, fetchNextPage, hasNextPage);
  const total = threads?.pages[0]?.count;

  const handleThreadClick = async (thread: Thread) => {
    if (!currentUser) return;

    const threadWithChannel = !thread.channel ? await createThreadChannel(thread) : thread;

    const isNotMember = !threadWithChannel.channel?.members.find(
      (m) => m.user_id === currentUser.user_id,
    );

    const threadUser = pickThreadUserProps(currentUser);

    if (isNotMember) {
      await addSpectator(threadWithChannel, threadUser);
    }

    if (!connectedUser) {
      connectUser(threadUser);
    }

    selectThread(threadWithChannel);
  };

  return (
    <Box height="100%">
      {isLoading || !threads ? (
        <>
          {/* will be used when the unread threads sorting is implemented */}
          {/* <ThreadsUnread isLoading /> */}
          <ThreadSkeletonRows rowCount={SKELETON_COUNT} />
        </>
      ) : (
        <>
          {/* will be used when the unread threads sorting is implemented */}
          {/* <ThreadsUnread /> */}
          {entities.map((thread) => (
            <ThreadItem key={thread.id} thread={thread} onClick={() => handleThreadClick(thread)} />
          ))}

          {hasNextPage && (
            <>
              <div ref={loaderRef} />
              <ThreadSkeletonRows
                rowCount={Math.min(
                  SKELETON_COUNT,
                  total && threads ? total - threads.pages.length * SKELETON_COUNT : SKELETON_COUNT,
                )}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
