import { ThreadParams } from '@schooly/components/threads';
import { ModalContent, ModalMain } from '@schooly/style';
import { FC } from 'react';

import { MessageChannelContent } from './MessageChannelContent';

export const MessageModalPreviewSelectedThread: FC<ThreadParams> = (params) => {
  return (
    <ModalMain>
      <ModalContent
        sx={{
          px: 0,
          backgroundColor: 'common.lightBg',
          '.str-chat__virtual-list, .str-chat__container, .str-chat__channel': {
            backgroundColor: 'common.lightBg',
          },
        }}
      >
        <MessageChannelContent {...params} />
      </ModalContent>
    </ModalMain>
  );
};
