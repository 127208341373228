import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DoubleCheckIcon } from '@schooly/style';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Hint from '../../../../components/common/Hint';
import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import { PopoverSize } from '../../../../components/ui/Popover';
import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import {
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { useMessage } from '../../../../context/messages/useMessage';

export const MessagesModalPreviewRecipients: FC = () => {
  const { actualParents } = useMessage();

  const read = useMemo(() => actualParents.filter((item) => item.read).length, [actualParents]);
  const total = actualParents.length;
  return (
    <Box>
      <Typography variant="h2" mb={0} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormattedMessage id="messages-ReadStatisticsByStudents" />
        <Counter>{actualParents.length}</Counter>
      </Typography>

      {total > 0 && (
        <Stack
          direction="row"
          alignSelf="center"
          alignItems="center"
          gap={1}
          sx={(theme) => ({
            flex: '1 1 auto',
            justifyContent: 'flex-end',
            pr: 1,

            [theme.breakpoints.down('lg')]: {
              pr: 0,
            },
            pb: 1,
          })}
        >
          <IconButton sx={{ color: 'success.main', '&:hover': { color: 'success.main' } }}>
            <DoubleCheckIcon />
          </IconButton>
          <Typography component="div" color="success.main" fontWeight={500}>
            {read}/{total}
          </Typography>
        </Stack>
      )}
      <>
        {actualParents.map((parent) => (
          <GridRowStyled key={parent.school_user_relation.relation_id}>
            <GridRowItem noVerticalPadding>
              <GridRowName>
                <PersonCardBasic
                  user={parent.school_user_relation}
                  userType="parent"
                  isListItem
                  isUsernameClickable
                />
              </GridRowName>
              <GridRowCell>
                {parent.read ? (
                  <Hint
                    icon={
                      <IconButton
                        sx={{ color: 'success.main', '&:hover': { color: 'success.main' } }}
                      >
                        <DoubleCheckIcon />
                      </IconButton>
                    }
                    size={PopoverSize.Small}
                  >
                    <FormattedMessage id="messages-HasBeenRead" />
                  </Hint>
                ) : (
                  <IconButton sx={{ color: 'text.secondary' }}>
                    <DoubleCheckIcon />
                  </IconButton>
                )}
              </GridRowCell>
            </GridRowItem>
          </GridRowStyled>
        ))}
      </>
    </Box>
  );
};
