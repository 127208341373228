import { Card, CardActionArea, CardProps, Skeleton, Stack } from '@mui/material';
import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

export type SimpleCardProps = {
  actions?: ReactNode;
  onItemClick?: () => void;
  avatar?: ReactNode;
  selected?: boolean;
  loadingState?: {
    avatarSize: number;
    skeletons: ReactNode[];
  };
} & CardProps;

export const SimpleCard: FC<PropsWithChildren<SimpleCardProps>> = ({
  onItemClick,
  avatar,
  actions,
  children,
  loadingState,
  ...props
}) => {
  return (
    <Card
      {...props}
      sx={{
        borderRadius: 0,
        border: 'none',
        borderBottom: (theme) => `1px solid ${theme.palette.common.light2}`,
        pointerEvents: loadingState ? 'none' : 'auto',
        ...props.sx,
      }}
    >
      <CardActionArea
        onClick={onItemClick}
        sx={{
          padding: 1,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1} width="100%">
            {loadingState ? (
              <Skeleton
                variant="circular"
                width={loadingState.avatarSize}
                height={loadingState.avatarSize}
              />
            ) : (
              avatar
            )}

            {loadingState ? (
              <Stack gap={1} flex={1}>
                {loadingState.skeletons.map((skeleton, index) => (
                  <Fragment key={index}>{skeleton}</Fragment>
                ))}
              </Stack>
            ) : (
              children
            )}
          </Stack>
          <Stack minWidth={58}>{actions}</Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
