import { Avatar, Box, Stack } from '@mui/material';
import React from 'react';

import { AvatarAuth, AvatarAuthProps } from './AvatarAuth';

export type AvatarAuthMultipleProps = {
  users: AvatarAuthProps['user'][];
};

export const AvatarAuthMultiple: React.FC<AvatarAuthMultipleProps> = ({ users }) => {
  if (users.length === 0) return null;
  const firstUser = users[0];
  const secondUser = users[1];

  // Return early if users are somehow undefined
  if (!firstUser || !secondUser) return null;

  const avatarSize = !secondUser ? 58 : 38;

  const avatarProps = {
    width: avatarSize,
    height: avatarSize,
  };

  if (!secondUser) return <AvatarAuth user={firstUser} sx={avatarProps} />;

  const secondAvatarProps = {
    ...avatarProps,
    borderRadius: '50%',
    border: '2px solid white',
  };

  return (
    <Stack sx={{ position: 'relative', width: 58, height: 58 }}>
      <Box sx={{ position: 'absolute', left: 0 }}>
        <AvatarAuth user={firstUser} sx={avatarProps} />
      </Box>
      <Box sx={{ position: 'absolute', left: avatarSize - 16, top: 22, zIndex: 1 }}>
        {users.length > 2 ? (
          <Avatar
            sx={{
              ...secondAvatarProps,
              backgroundColor: 'common.lightBg',
              color: 'common.grey3',
            }}
          >
            {`+${users.length - 1}`}
          </Avatar>
        ) : (
          <AvatarAuth user={secondUser} sx={secondAvatarProps} />
        )}
      </Box>
    </Stack>
  );
};
