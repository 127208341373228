import {
  Backdrop,
  Box,
  Checkbox,
  ClickAwayListener,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ProductBillingType, ProductForm } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  CheckboxIcon,
  DoneIcon,
  DropdownIcon,
  InformationIcon,
  RadioGroupCard,
} from '@schooly/style';
import { ReactNode, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  getProductSubscriptionExampleOptions,
  ProductSubscriptionExampleContainer,
  ProductSubscriptionExampleHeader,
  ProductSubscriptionExampleOption,
} from './ProductSubscriptionExample';
import { ProductSubscriptionType } from './ProductSubscriptionOption';

type ProductTypeUniqueSelectProps = {
  form: UseFormReturn<ProductForm>;
  opened?: boolean;
  width?: number;
  error?: boolean;
  canEdit?: boolean;
  onSelect: (value: 'unique_types' | 'many_types') => void;
  children?: (opened: boolean) => ReactNode;
};

const DEFAULT_WIDTH = 332;

export type DropdownProps = {
  open: () => void;
  close: () => void;
};

export const ProductSubscriptionTypeSelect = ({
  form,
  width,
  opened: initialOpened,
  error,
  canEdit,
  onSelect,
  children,
}: ProductTypeUniqueSelectProps) => {
  const { formatMessage } = useIntl();

  const currentWidth = width ?? DEFAULT_WIDTH;
  const [opened, open, close] = useFlag(initialOpened);

  const uniqueTypes = form.watch('unique_types');
  const type = form.watch('type');

  const isRecurring = type === ProductBillingType.Recurring;

  const canEditRecurring = canEdit && isRecurring;

  const exampleOptions = getProductSubscriptionExampleOptions(
    ProductSubscriptionType.ManyTypesAssignment,
  );

  const tooltipTitle = useMemo(
    () => (
      <Stack height="100%" flexDirection="column">
        {canEdit && (
          <Stack gap={1}>
            <RadioGroupCard
              checked={uniqueTypes}
              labelTextId="products-ProductAssignmentType-UniqueTypes"
              onChange={() => !uniqueTypes && onSelect('unique_types')}
            />

            <RadioGroupCard
              checked={!uniqueTypes}
              labelTextId="products-ProductAssignmentType-ManyTypesAssignment"
              onChange={() => uniqueTypes && onSelect('many_types')}
            />
          </Stack>
        )}

        <Stack flex="1 0 50%" justifyContent="center">
          <ProductSubscriptionExampleContainer>
            <Stack gap={1}>
              {uniqueTypes && (
                <>
                  <ProductSubscriptionExampleHeader
                    textId="products-SubscriptionExample-Tuition"
                    color="primary.main"
                  />

                  <ProductSubscriptionExampleOption
                    textId="products-SubscriptionExample-Primary"
                    color="primary.main"
                    icon={
                      <Icon fontSize="small" sx={(theme) => ({ marginX: theme.spacing(0.25) })}>
                        <DoneIcon />
                      </Icon>
                    }
                  />
                </>
              )}

              {!uniqueTypes && (
                <>
                  <ProductSubscriptionExampleHeader
                    textId="products-SubscriptionExample-Course"
                    color="primary.main"
                  />

                  <Stack gap={0.5}>
                    {exampleOptions.map(({ textId, checked }) => {
                      return (
                        <ProductSubscriptionExampleOption
                          key={textId}
                          textId={textId}
                          color=""
                          icon={
                            <Checkbox
                              sx={{ padding: 0, '.svg-icon': { height: 14 } }}
                              checked={checked}
                              checkedIcon={
                                <Icon sx={{ '.svg-icon circle': { fill: 'primary.main' } }}>
                                  <CheckboxIcon className="reset-svg-currentColor" />
                                </Icon>
                              }
                            />
                          }
                        />
                      );
                    })}
                  </Stack>
                </>
              )}
            </Stack>
          </ProductSubscriptionExampleContainer>
        </Stack>
      </Stack>
    ),
    [canEdit, uniqueTypes, exampleOptions, onSelect],
  );

  const tooltipTrigger = useMemo(
    () => (
      <Stack
        flexDirection="row"
        sx={{
          cursor: canEditRecurring ? 'pointer' : 'default',
          position: 'relative',
          zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
        }}
        alignItems="center"
        onClick={canEditRecurring ? open : undefined}
      >
        <Stack flexDirection="row" alignItems="center" gap={1} pr={1} sx={{ overflowX: 'auto' }}>
          <Typography variant="h3" color={error ? 'error.main' : 'primary.main'}>
            {formatMessage({
              id: uniqueTypes
                ? 'products-ProductAssignmentType-UniqueTypes'
                : 'products-ProductAssignmentType-ManyTypesAssignment',
            })}
          </Typography>

          {!isRecurring && (
            <Tooltip
              title={formatMessage({ id: 'products-ProductAssignmentType-UniqueTypesTooltip' })}
            >
              <IconButton inverse>
                <InformationIcon />
              </IconButton>
            </Tooltip>
          )}

          {canEditRecurring && (
            <IconButton inverse={!opened} size="small" sx={{ rotate: opened ? '180deg' : 0 }}>
              <DropdownIcon />
            </IconButton>
          )}
        </Stack>
        {children?.(opened)}
      </Stack>
    ),
    [children, error, opened, uniqueTypes, isRecurring, formatMessage, canEditRecurring, open],
  );

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <Box sx={{ overflowX: 'auto' }}>
          {!canEdit && !isRecurring && tooltipTrigger}

          {!canEdit && isRecurring && (
            <Tooltip
              placement="bottom-start"
              componentsProps={{
                tooltip: {
                  sx: (theme) => ({
                    width: currentWidth,
                    maxWidth: currentWidth,
                    height: '182px',
                    borderRadius: theme.spacing(1),
                    padding: 2,
                    overflow: 'hidden',
                    margin: `${theme.spacing(0, 0, 0)} !important`,
                  }),
                },
              }}
              title={tooltipTitle}
            >
              {tooltipTrigger}
            </Tooltip>
          )}

          {canEdit && (
            <Tooltip
              onClose={close}
              open={opened}
              placement="bottom-start"
              componentsProps={{
                tooltip: {
                  sx: (theme) => ({
                    width: currentWidth,
                    maxWidth: currentWidth,
                    height: canEdit ? '294px' : '182px',
                    borderRadius: theme.spacing(1),
                    padding: 2,
                    overflow: 'hidden',
                    margin: `${theme.spacing(0, 0, 0)} !important`,
                  }),
                },
              }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipTitle}
            >
              {tooltipTrigger}
            </Tooltip>
          )}
        </Box>
      </ClickAwayListener>
    </>
  );
};
