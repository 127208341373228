import { Box, Button, Skeleton, Stack, TextField, TextFieldProps } from '@mui/material';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useMessageInputContext } from 'stream-chat-react';

export const ChannelInput = () => {
  const { text, handleChange, handleSubmit } = useMessageInputContext();
  const { formatMessage } = useIntl();

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && !event.shiftKey && !!text) {
        event.preventDefault();
        handleSubmit(event);
      }
    },
    [handleSubmit, text],
  );

  return (
    <Stack
      direction="row"
      sx={{
        gap: 1.25,
        px: 2,
      }}
    >
      <ChannelTextField
        value={text}
        onChange={(event) => {
          handleChange(event as any);
        }}
        onKeyDown={handleKeyDown}
        placeholder={formatMessage({ id: 'messages-MessageTitle' })}
      />
      <Stack alignSelf="flex-end">
        <Button disabled={!text} onClick={handleSubmit}>
          {formatMessage({ id: 'messages-ThreadChannelSend' })}
        </Button>
      </Stack>
    </Stack>
  );
};

export const ChannelTextField: FC<Omit<TextFieldProps, 'variant'> & { isLoading?: boolean }> = ({
  isLoading,
  ...props
}) => {
  return (
    <Box sx={{ width: '100%', position: 'relative', pointerEvents: isLoading ? 'none' : 'auto' }}>
      {isLoading && (
        <Box sx={{ zIndex: 1, position: 'absolute', top: 14, left: 10, width: '35%' }}>
          <Skeleton variant="rectangular" height={16} />
        </Box>
      )}
      <TextField
        {...props}
        fullWidth
        maxRows={5}
        minRows={1}
        multiline
        sx={{
          '& .MuiOutlinedInput-input': {
            backgroundColor: 'white',
            borderRadius: '8px',
          },
          '.MuiInputBase-input': {
            img: {
              margin: '0px !important',
              display: 'inline !important',
              height: 'auto',
              width: '50px',
            },
          },
          width: '100%',
          '&& .MuiFilledInput-root': {
            backgroundColor: 'white !important',
          },
        }}
      />
    </Box>
  );
};
