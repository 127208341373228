import { Box, Tooltip, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

const isOverflowing = (element: HTMLElement | null): boolean => {
  if (!element) return false;
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

export interface TruncatedTextProps {
  items: Array<string>;
  maxLines?: number;
  typographyProps?: React.ComponentProps<typeof Typography>;
  tooltipText?: string;
}

export const TruncatedText: FC<TruncatedTextProps> = ({
  items,
  maxLines = 2,
  typographyProps,
  tooltipText,
}) => {
  const { ref, width } = useResizeDetector<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleCount, setVisibleCount] = useState(items.length);

  const fullText = useMemo(() => items.join(', '), [items]);

  useEffect(() => {
    if (!containerRef.current || !width) return;

    items.forEach((_, i) => {
      const nameElement = containerRef.current?.children[i] as HTMLElement;
      if (nameElement) nameElement.style.display = 'inline';
    });

    if (!isOverflowing(containerRef.current)) {
      setVisibleCount(items.length);
      return;
    }

    let lastVisibleIndex = items.length - 1;

    for (let i = items.length - 1; i > 0; i--) {
      const nameElement = containerRef.current?.children[i] as HTMLElement;
      if (nameElement) nameElement.style.display = 'none';

      if (!isOverflowing(containerRef.current)) {
        lastVisibleIndex = i - 1;
        break;
      }
    }

    setVisibleCount(lastVisibleIndex + 1);
  }, [items, width]);

  const hiddenCount = items.length - visibleCount;

  const tooltipTitle = tooltipText ?? fullText;

  return (
    <Tooltip arrow title={hiddenCount > 0 ? tooltipTitle : ''}>
      <Box ref={ref} style={{ width: '100%' }}>
        <Typography
          ref={containerRef}
          {...typographyProps}
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: maxLines,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            lineHeight: 1.5,
            ...typographyProps?.sx,
          }}
        >
          {items.map((name, i, array) => (
            <Typography key={`name-${i}`} component="span" {...typographyProps}>
              {i < array.length - 1 ? `${name} ` : name}
            </Typography>
          ))}
          {hiddenCount > 0 && (
            <Typography component="span" {...typographyProps}>
              {` +${hiddenCount}`}
            </Typography>
          )}
        </Typography>
      </Box>
    </Tooltip>
  );
};
