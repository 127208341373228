import { Divider, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { DateSeparatorProps, useChannelStateContext } from 'stream-chat-react';

import { formatMessageDate } from '../utils';

export const ChannelDateSeparator: FC<DateSeparatorProps> = ({ date, unread }) => {
  const formattedDate = formatMessageDate(date);
  const { channel } = useChannelStateContext();

  if (unread && !channel.state.unreadCount) return null;

  return (
    <Stack direction="row" alignItems="center" pb={2}>
      <Divider
        sx={{
          flex: 1,
          ml: -4,
          borderColor: unread ? 'success.main' : undefined,
        }}
      />
      <Typography color="primary.main" px={1}>
        {formattedDate}
      </Typography>
      <Divider
        sx={{
          flex: 1,
          mr: -4,
          borderColor: unread ? 'success.main' : undefined,
        }}
      />
    </Stack>
  );
};
