import { Box } from '@mui/material';
import React, { FC } from 'react';

import { RichText } from '../../../components/ui/Input';
import { useMessage } from '../../../context/messages/useMessage';
import { MessagesModalPreviewSendTo } from './MessagesModalPreviewSendTo/MessagesModalPreviewSendTo';

export const MessageModalPreviewMessageInfo: FC = () => {
  const { body, attachments, actions } = useMessage();

  return (
    <Box>
      <MessagesModalPreviewSendTo />

      <RichText
        value={body}
        files={attachments}
        onAttachmentCardClick={actions.fetchAttachment}
        readOnly
      />
    </Box>
  );
};
