import 'stream-chat-react/dist/css/v2/index.css';

import { Button, Stack } from '@mui/material';
import { GET_THREAD_QUERY, Thread, ThreadUser, useGetThreadQuery } from '@schooly/api';
import {
  CHANNEL_TYPE,
  ChannelDateSeparator,
  ChannelInput,
  ChannelLoading,
  Message,
  NoChannelSelectedStub,
  NoMessagesStub,
  ThreadParams,
  useThreads,
} from '@schooly/components/threads';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Channel,
  MessageInput,
  useChannelActionContext,
  useChannelStateContext,
  VirtualizedMessageList,
} from 'stream-chat-react';

export const MessageChannelContent: FC<ThreadParams> = ({ schoolId }) => {
  const {
    connectedUser,
    selectedThread,
    client,
    isUserConnecting,
    isChannelCreating,
    isThreadMembersUpdating,
  } = useThreads();

  const { data: thread, isFetching: isThreadFetching } = useGetThreadQuery(
    {
      school_id: schoolId,
      thread_id: selectedThread?.id!,
    },
    {
      enabled: !!selectedThread?.id,
    },
  );

  const isLoading =
    isUserConnecting || isChannelCreating || isThreadMembersUpdating || isThreadFetching;

  if (!selectedThread?.channel) {
    return <NoChannelSelectedStub />;
  }

  if (isLoading || !client || !connectedUser) {
    return <ChannelLoading />;
  }

  const hasAccessToChannel = thread?.channel?.members.some(
    (s) => s.user_id === connectedUser.user_id,
  );

  if (!hasAccessToChannel || !thread) return null;

  return (
    <Channel
      Message={Message}
      Input={ChannelInput}
      EmptyStateIndicator={NoMessagesStub}
      DateSeparator={ChannelDateSeparator}
      UnreadMessagesSeparator={() => null}
      LoadingIndicator={() => null}
      UnreadMessagesNotification={() => null}
      channel={client.getChannelById(CHANNEL_TYPE, selectedThread.channel.id, {
        watch: true,
      })}
    >
      <MessageChannel schoolId={schoolId} thread={thread} connectedUser={connectedUser} />
    </Channel>
  );
};

type MessageChannelProps = {
  schoolId: string;
  thread: Thread;
  connectedUser: ThreadUser;
};

const MessageChannel: FC<MessageChannelProps> = ({ schoolId, thread, connectedUser }) => {
  const { jumpToFirstUnreadMessage, markRead } = useChannelActionContext();
  const { addMember } = useThreads();
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const { channel } = useChannelStateContext();

  useEffect(() => {
    jumpToFirstUnreadMessage();
  }, [jumpToFirstUnreadMessage]);

  const isSpectator = thread.channel?.members.some(
    (s) => s.user_id === connectedUser.user_id && s.channel_role === 'channel_spectator',
  );

  const onJoinChannel = useCallback(async () => {
    await addMember(thread, connectedUser);

    await queryClient.invalidateQueries([
      GET_THREAD_QUERY,
      {
        school_id: schoolId,
        thread_id: thread.id,
      },
    ]);
  }, [addMember, connectedUser, queryClient, schoolId, thread]);

  const messageInput = isSpectator ? (
    <Stack alignItems="center" justifyContent="center">
      <Button onClick={onJoinChannel}>{formatMessage({ id: 'messages-ThreadChannelJoin' })}</Button>
    </Stack>
  ) : (
    <MessageInput />
  );

  return (
    <Stack height="100%" width="100%">
      <VirtualizedMessageList
        additionalVirtuosoProps={{
          endReached: async () => {
            if (channel.state.unreadCount > 0) {
              markRead({
                updateChannelUiUnreadState: true,
              });
            }
          },
        }}
        disableDateSeparator={false}
        Message={Message}
      />
      {messageInput}
    </Stack>
  );
};
