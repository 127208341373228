import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { useRouter } from '../../../context/router/useRouter';
import { ModalPanelProps } from './Modal.styled';
import { ModalHeaderActions, ModalHeaderStyled } from './ModalHeader.styled';
import { ModalHeaderGoBack } from './ModalHeaderGoBack';

export interface ModalHeaderProps extends Omit<ModalPanelProps, 'title'> {
  title?: React.ReactNode;
  multiline?: boolean;
  withGoBack?: boolean;
  subHeader?: React.ReactNode;
  avatar?: React.ReactNode;
}

export const ModalHeaderV2: FC<ModalHeaderProps> = ({
  title,
  children,
  multiline,
  withGoBack,
  subHeader,
  avatar,
  ...props
}) => {
  const { stack } = useRouter();
  const canGoBack = withGoBack && stack.length > 1;

  return (
    <ModalHeaderStyled
      withGoBack={canGoBack}
      sx={{
        paddingTop: 0,
        paddingBottom: 1,
      }}
      {...props}
    >
      <Stack
        pt={1.5}
        direction="row"
        gap={avatar || canGoBack ? 2 : 0}
        sx={{
          minWidth: 0,
          width: '100%',
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" gap={2.25}>
          {canGoBack && <ModalHeaderGoBack />}
          {avatar && avatar}
        </Stack>

        <Stack width={canGoBack && avatar ? '90%' : '95%'}>
          <Stack overflow="hidden">
            <Typography variant="h2">{title}</Typography>
          </Stack>
          <Box minHeight={22}>{subHeader && subHeader}</Box>
        </Stack>
      </Stack>
      <Stack pt={2.25}>{children && <ModalHeaderActions>{children}</ModalHeaderActions>}</Stack>
    </ModalHeaderStyled>
  );
};
