import {
  getToken,
  RootEntityType,
  Thread,
  ThreadUser,
  useCreateThreadChannel,
  useUpdateThreadMembers,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';

export type ThreadParams = {
  schoolId: string;
  rootEntityType: RootEntityType;
  rootEntityId: string;
};

export type ThreadsContextProps = {
  client: StreamChat<DefaultGenerics> | null;
  isLoading: boolean;
  connectedUser?: ThreadUser;
  selectedThread?: Thread;
  selectThread: (thread: Thread) => void;
  addSpectator: (thread: Thread, spectator: ThreadUser) => Promise<void>;
  addMember: (thread: Thread, member: ThreadUser) => Promise<void>;
  archiveThread: (thread: Thread) => Promise<void>;
  connectUser: (user: ThreadUser) => void;
  isChannelCreating: boolean;
  createThreadChannel: (thread: Thread) => Promise<Thread>;
  isThreadMembersUpdating: boolean;
  isUserConnecting: boolean;
};

export const ThreadsContext = createContext<ThreadsContextProps>({
  client: null,
  isLoading: false,
  connectedUser: undefined,
  selectedThread: undefined,
  selectThread: () => {},
  addSpectator: async () => {},
  addMember: async () => {},
  archiveThread: async () => {},
  connectUser: () => {},
  createThreadChannel: async () => ({} as Thread),
  isChannelCreating: false,
  isThreadMembersUpdating: false,
  isUserConnecting: false,
});

type WithThreadsProps = PropsWithChildren<{
  schoolId: string;
  rootEntityType: RootEntityType;
  rootEntityId: string;
  apiKey: string;
}>;

export const WithThreads: FC<WithThreadsProps> = ({
  children,
  rootEntityType,
  rootEntityId,
  schoolId,
  apiKey,
}) => {
  const client = useRef<StreamChat<DefaultGenerics>>(new StreamChat(apiKey));
  const [connectedUser, setConnectedUser] = useState<ThreadUser>();
  const [thread, setThread] = useState<Thread>();
  const [isUserConnecting, setIsUserConnecting] = useState(false);

  const createThreadChannelMutation = useCreateThreadChannel();
  const updateThreadMembersMutation = useUpdateThreadMembers();
  const { showError } = useNotifications();

  const selectThread = useCallback((thread: Thread) => {
    setThread(thread);
  }, []);

  const connectUser = useCallback(
    async (user: ThreadUser) => {
      if (connectedUser) return;

      try {
        setIsUserConnecting(true);
        const { token } = await getToken({
          school_id: schoolId,
          root_entity_type: rootEntityType,
          root_entity_id: rootEntityId,
        });

        await client.current.connectUser(
          {
            id: user.user_id,
            name: getUserFullName(user),
            image: user.profile_picture,
            ...user,
          },
          token,
        );
        setConnectedUser(user);
      } catch (error) {
        throw new Error(`Failed to connect user: ${error}`);
      } finally {
        setIsUserConnecting(false);
      }
    },
    [client],
  );

  const addSpectator = useCallback(
    async (thread: Thread, spectator: ThreadUser) => {
      await updateThreadMembersMutation.mutateAsync(
        {
          thread_id: thread.id,
          spectators: [spectator],
          school_id: schoolId,
        },
        {
          onError: showError,
        },
      );
    },
    [updateThreadMembersMutation, schoolId, rootEntityType, rootEntityId, showError],
  );

  const addMember = useCallback(
    async (thread: Thread, member: ThreadUser) => {
      await updateThreadMembersMutation.mutateAsync(
        {
          thread_id: thread.id,
          new_members: [member],
          school_id: schoolId,
        },
        {
          onError: showError,
        },
      );
    },
    [updateThreadMembersMutation, schoolId, rootEntityType, rootEntityId, showError],
  );

  const createThreadChannel = useCallback(
    async (thread: Thread) => {
      return createThreadChannelMutation.mutateAsync(
        {
          thread_id: thread.id,
          school_id: schoolId,
        },
        {
          onError: showError,
        },
      );
    },
    [createThreadChannelMutation, schoolId, rootEntityType, rootEntityId, showError],
  );

  const archiveThread = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (_: Thread) => {
      // Will be implemented with actual archive logic
    },
    [],
  );

  useEffect(
    () => () => {
      client.current.disconnectUser();
      setConnectedUser(undefined);
    },
    [],
  );

  return (
    <ThreadsContext.Provider
      value={{
        client: client.current,
        isLoading: false,
        selectedThread: thread,
        selectThread,
        addSpectator,
        addMember,
        archiveThread,
        connectedUser,
        connectUser,
        createThreadChannel,
        isUserConnecting,
        isChannelCreating: createThreadChannelMutation.isLoading,
        isThreadMembersUpdating: updateThreadMembersMutation.isLoading,
      }}
    >
      <Chat client={client.current}>{children}</Chat>
    </ThreadsContext.Provider>
  );
};

export const useThreads = () => {
  return useContext(ThreadsContext);
};
