import { SyncUser } from '@schooly/api';
import { ThreadUser } from '@schooly/api';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import {
  format,
  isSameWeek,
  isSameYear,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
} from 'date-fns';
import { useIntl } from 'react-intl';
export function pickThreadUserProps(user: SyncUser): ThreadUser {
  return {
    user_id: user.user_id,
    given_name: user.given_name ?? '',
    last_name: user.last_name,
    profile_picture: user.profile_picture ?? null,
    known_as: user.known_as ?? '',
    relation_id: user.relation_id ?? null,
  };
}

export function formatChannelDate(date: Date): string {
  // TODO move to constants, use existing
  if (isToday(date)) {
    return format(date, 'h:mm a');
  } else if (isThisWeek(date)) {
    return format(date, 'EEE').toUpperCase();
  } else if (isThisYear(date)) {
    return format(date, 'd MMM');
  } else {
    return format(date, 'd MMM yyyy');
  }
}

export const formatMessageDate = (date: Date): string => {
  // TODO move to constants, use existing
  const { formatMessage } = useIntl();

  if (isToday(date)) return formatMessage({ id: 'messages-ThreadChannelMessageToday' });
  if (isYesterday(date)) return formatMessage({ id: 'messages-ThreadChannelMessageYesterday' });
  if (isSameWeek(date, newDateTimezoneOffset(), { weekStartsOn: 1 })) return format(date, 'EEEE');
  if (isSameYear(date, newDateTimezoneOffset())) return format(date, 'd MMM');

  return format(date, 'd MMM yyyy');
};
