import { Stack, Typography } from '@mui/material';
import { CollapsedList } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { MessagesModalPreviewSendToParents } from './MessagesModalPreviewSendToParents';
import { MessagesModalPreviewSendToStaff } from './MessagesModalPreviewSendToStaff';

export const MessagesModalPreviewSendTo: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Stack gap={1} pb={2}>
      <CollapsedList
        initialExpanded
        title={<Typography variant="h2">{formatMessage({ id: 'messages-Recipients' })}</Typography>}
      >
        <Stack pt={2} pb={1}>
          <MessagesModalPreviewSendToParents />
        </Stack>
      </CollapsedList>
      <CollapsedList
        initialExpanded
        title={<Typography variant="h2">{formatMessage({ id: 'messages-CCStaff' })}</Typography>}
      >
        <Stack pt={2}>
          <MessagesModalPreviewSendToStaff />
        </Stack>
      </CollapsedList>
    </Stack>
  );
};
