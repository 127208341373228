import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { WithAvatar, WithName } from '@schooly/api';
import { EmptyListSvg, SimpleCard, SimpleCardProps } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { MessageContent } from '../ChannelMessages/Message';
import { ChannelTextField } from './ChannelInput';

export const NoChannelSelectedStub = () => {
  const { formatMessage } = useIntl();

  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Stack gap={3} alignItems="center">
        <Typography variant="h3" color="common.grey2">
          {formatMessage({ id: 'messages-ThreadNoChannelSelected' })}
        </Typography>
        <Typography variant="h3" color="common.grey">
          {formatMessage({ id: 'messages-ThreadSelectChatToView' })}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const NoMessagesStub = () => {
  const { formatMessage } = useIntl();

  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Stack
        alignItems="center"
        gap={3}
        sx={{
          ' svg': {
            width: 180,
            height: 180,
          },
        }}
      >
        <EmptyListSvg />
        <Typography textAlign="center" variant="h3" color="common.grey2" maxWidth={280}>
          {formatMessage({ id: 'messages-NoMessages' })}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ChannelLoading = () => {
  return (
    <Stack height="100%" justifyContent="space-between" px={2}>
      <Stack gap={8}>
        <Stack direction="row" gap={2} width="100%" justifyContent="space-between">
          <Box width="100%">
            <ChannelUserCard isLoading />
          </Box>
          <Box width="100%">
            <ChannelUserCard isLoading />
          </Box>
        </Stack>
        <Stack gap={0.5}>
          <MessageContent
            isLoading
            isMyMessage={false}
            minWidth="370px"
            user={{
              given_name: 'John',
              last_name: 'Doe',
            }}
          />
          <MessageContent minWidth="118px" isLoading isMyMessage={false} />
        </Stack>
      </Stack>
      <ChannelTextField isLoading />
    </Stack>
  );
};

type ChannelUserCardProps = {
  user?: WithName & WithAvatar;
  status?: string;
  isLoading?: boolean;
} & SimpleCardProps;

export const ChannelUserCard: FC<ChannelUserCardProps> = ({ user, status, isLoading }) => {
  return (
    <SimpleCard
      sx={{
        borderBottom: 'none',
        borderRadius: (theme) => theme.spacing(1),
      }}
      loadingState={
        isLoading
          ? {
              avatarSize: 30,
              skeletons: [<Skeleton variant="text" width="44%" height={18} />],
            }
          : undefined
      }
      actions={
        <Stack>
          <Typography color="common.grey3">{status}</Typography>
        </Stack>
      }
    >
      <Stack direction="row" gap={1} flex={1}>
        <Typography variant="h3" color="common.grey2">
          {user ? getUserFullName(user) : ''}
        </Typography>
      </Stack>
    </SimpleCard>
  );
};
