import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { InformationIcon, TagSelect } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import {
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { SelectedCriteriaList } from '../../../../components/uikit-components/SelectedCriteriaList/SelectedCriteriaList.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getRouteModalPathname } from '../../../../helpers/misc';
import { getDisplayedCriteria } from '../../helpers';

export const MessagesModalPreviewSendToStaff: FC = () => {
  const { staff, staffCriteria, staffByIndividualStudent, criteriaDate } = useMessage();
  const { formatMessage } = useIntl();

  return (
    <>
      {Boolean(staffCriteria.length || staff.length) && (
        <SelectedCriteriaList>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {getDisplayedCriteria({
              selectedCriteria: staffCriteria,
              children: (
                <Stack direction="row" alignItems="center" spacing={0.5} pr={1}>
                  <Typography variant="h4">
                    <FormattedMessage id="messages-StaffByCriteria" />
                  </Typography>

                  {!!criteriaDate && (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <TagSelect
                        label={
                          <Typography variant="h3" color={(theme) => theme.palette.common.grey2}>
                            {format(new Date(criteriaDate), SHORT_FORMATTED_DATE_FORMAT_FNS)}
                          </Typography>
                        }
                        sx={(theme) => ({
                          '&.MuiChip-root': {
                            backgroundColor: theme.palette.background.paper,
                          },
                        })}
                      />

                      <Tooltip
                        title={formatMessage({ id: 'messages-ParentsOfStudentsPreviewTooltip' })}
                        placement="top"
                      >
                        <IconButton>
                          <InformationIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
              ),
            })}
          </Stack>
          {
            /* Individual staff */
            Boolean(staff?.length) && (
              <Stack direction="row" mt={1} alignItems="baseline" gap={1}>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  <FormattedMessage id="messages-StaffIndividually" />
                </Typography>

                {staff.map((staff, index, arr) => (
                  <>
                    <Link key={staff.user_id} to={getRouteModalPathname('staff', staff)}>
                      <TagSelect
                        sx={(theme) => ({
                          '&.MuiChip-root': {
                            backgroundColor: theme.palette.background.paper,
                            color: 'common.grey2',
                          },
                        })}
                        onClick={() => getRouteModalPathname('staff', staff)}
                        label={getUserFullName(staff)}
                      />
                    </Link>
                    {index < arr.length - 1 && (
                      <Typography variant="h3" color="common.grey2">
                        ,
                      </Typography>
                    )}
                  </>
                ))}
              </Stack>
            )
          }
        </SelectedCriteriaList>
      )}

      {/* Staff by individual students */}
      {Boolean(staffByIndividualStudent.length) && (
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            <FormattedMessage id="messages-StaffOfStudents" />
          </Typography>
          <Box>
            {staffByIndividualStudent.map((item) => (
              <GridRowStyled key={item.relation_id}>
                <GridRowItem noVerticalPadding>
                  <GridRowName>
                    <PersonCardBasic user={item} userType="staff" isListItem isUsernameClickable />
                  </GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
