import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ChannelMemberResponse, DefaultGenerics, FormatMessageResponse } from 'stream-chat';

import { THREAD_API_URL } from '../config';
import { ApiError, PagedResponse } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import * as api from './requests';

export enum ChannelRole {
  CHANNEL_MEMBER = 'channel_member',
  CHANNEL_OWNER = 'channel_owner',
  CHANNEL_SPECTATOR = 'channel_spectator',
}

export type ThreadUser = {
  profile_picture: string | null;
  last_name: string;
  given_name: string;
  known_as: string;
  user_id: string;
  relation_id: string | null;
};

export type StreamChannel = {
  id: string;
  unread_count: number;
  last_read_message_id: string | null;
  member_count: number;
  message_count: number;
  last_message: FormatMessageResponse<DefaultGenerics> | null;
  members: ChannelMemberResponse<DefaultGenerics>[];
};
export enum RootEntityType {
  MESSAGE = 'message',
}

export type RootEntityContextParams = {
  school_id: string;
  root_entity_type: RootEntityType;
  root_entity_id: string;
};

export enum ThreadStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export type Thread = {
  id: string;
  name: string | null;
  channel: StreamChannel | null;
  status: ThreadStatus;
  parents: ThreadUser[];
  children: ThreadUser[];
  invited_staff: ThreadUser[];
  spectators: ThreadUser[];
  creator_id: string;
} & RootEntityContextParams;

export type Token = {
  token: string;
};

export type GetThreadsParams = {
  page_size?: number;
  page_number?: number;
} & RootEntityContextParams;

export type GetThreadParams = {
  thread_id: string;
  school_id: string;
};

export type UpdateThreadMembersParams = {
  thread_id: string;
  school_id: string;
  new_members?: ThreadUser[];
  spectators?: ThreadUser[];
};

export type UpdateThreadArchiveParams = {
  thread_id: string;
  school_id: string;
  archive: boolean;
};

export const getThreads = async (params: GetThreadsParams): Promise<PagedResponse<Thread>> => {
  return api.get(`${THREAD_API_URL}/threads`, {
    params,
  });
};

export const getThread = async (params: GetThreadParams): Promise<Thread> => {
  const { thread_id, ...queryParams } = params;
  return api.get(`${THREAD_API_URL}/threads/${thread_id}`, {
    params: queryParams,
  });
};

export const getToken = async (params: RootEntityContextParams): Promise<Token> => {
  const { ...queryParams } = params;
  return api.get(`${THREAD_API_URL}/threads/token`, {
    params: queryParams,
  });
};

export const createThreadChannel = async ({
  thread_id,
  ...params
}: GetThreadParams): Promise<Thread> => {
  return api.post(`${THREAD_API_URL}/threads/${thread_id}`, params);
};

export const updateThreadMembers = async (params: UpdateThreadMembersParams): Promise<Thread> => {
  const { thread_id, ...bodyParams } = params;
  return api.patch(`${THREAD_API_URL}/threads/${thread_id}/members`, bodyParams);
};

export const updateThreadArchiveStatus = async (
  params: UpdateThreadArchiveParams,
): Promise<Thread> => {
  const { thread_id, ...bodyParams } = params;
  return api.patch(`${THREAD_API_URL}/threads/${thread_id}/archive`, bodyParams);
};

export const GET_THREADS_QUERY = `${THREAD_API_URL}/GET_THREADS_QUERY`;
export const GET_THREAD_QUERY = `${THREAD_API_URL}/GET_THREAD_QUERY`;
export const GET_TOKEN_QUERY = `${THREAD_API_URL}/GET_TOKEN_QUERY`;
export const GET_CONNECT_USER_QUERY = `${THREAD_API_URL}/GET_CONNECT_USER_QUERY`;

export const useGetThreadsQuery = (
  initialParams: GetThreadsParams,
  options?: RQUseInfiniteQueryOptions<PagedResponse<Thread>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<Thread>, ApiError>(
    [GET_THREADS_QUERY, { ...params }],
    ({ pageParam }) =>
      getThreads({
        page_number: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const useGetThreadQuery = (params: GetThreadParams, options?: RQUseQueryOptions<Thread>) => {
  return useQuery<Thread, ApiError>([GET_THREAD_QUERY, params], () => getThread(params), options);
};

export const useGetTokenQuery = (
  params: RootEntityContextParams,
  options?: RQUseQueryOptions<Token>,
) => {
  return useQuery<Token, ApiError>([GET_TOKEN_QUERY, params], () => getToken(params), options);
};

export const useCreateThreadChannel = (
  options?: RQUseMutationOptions<Thread, GetThreadParams>,
): RQUseMutationResult<Thread, GetThreadParams> => {
  return useMutation(createThreadChannel, options);
};

export const useUpdateThreadMembers = (
  options?: RQUseMutationOptions<Thread, UpdateThreadMembersParams>,
): RQUseMutationResult<Thread, UpdateThreadMembersParams> => {
  return useMutation(updateThreadMembers, options);
};

export const useUpdateThreadArchiveStatus = (
  options?: RQUseMutationOptions<Thread, UpdateThreadArchiveParams>,
): RQUseMutationResult<Thread, UpdateThreadArchiveParams> => {
  return useMutation(updateThreadArchiveStatus, options);
};
