import { Stack } from '@mui/material';
import { RootEntityType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { WithThreads } from '@schooly/components/threads';
import { Loading, ModalSideBar } from '@schooly/style';
import React, { FC } from 'react';

import { CollapsiblePanel } from '../../../../components/common/CollapsiblePanel';
import { STREAM_API_KEY } from '../../../../config';
import { useMessage } from '../../../../context/messages/useMessage';
import AccessDenied from '../../../AccessDenied';
import { MessageModalPreviewSelectedThread } from './MessageModalPreviewSelectedThread';
import { MessageThreadList } from './MessageThreadList';

export const MessageModalPreviewThreads: FC = () => {
  const { schoolId, currentUser } = useAuth();
  const { messageId, hasAccessToThreads, hasThreads } = useMessage();

  if (!hasThreads || !hasAccessToThreads) {
    return <AccessDenied />;
  }

  if (!messageId || !currentUser || !schoolId || !STREAM_API_KEY) {
    if (!STREAM_API_KEY) console.error('STREAM_API_KEY was not provided');
    return (
      <Stack height="100%" justifyContent="center" alignItems="center" width="100%">
        <Loading />
      </Stack>
    );
  }

  const threadsParams = {
    schoolId,
    rootEntityType: RootEntityType.MESSAGE,
    rootEntityId: messageId,
    apiKey: STREAM_API_KEY,
  };

  return (
    <WithThreads {...threadsParams}>
      <Stack direction="row" height="100%">
        <CollapsiblePanel width={308}>
          <ModalSideBar sx={{ paddingTop: 1 }}>
            <MessageThreadList {...threadsParams} />
          </ModalSideBar>
        </CollapsiblePanel>

        <MessageModalPreviewSelectedThread {...threadsParams} />
      </Stack>
    </WithThreads>
  );
};
