import { Box, IconButton, Stack } from '@mui/material';
import React, { PropsWithChildren, ReactNode, useState } from 'react';

import { DropdownIcon } from '../../assets/icons';
import { Counter } from '../Counter';

export interface CollapsedListProps extends PropsWithChildren {
  title: ReactNode;
  counter?: number;
  fullWidth?: boolean;
  initialExpanded?: boolean;
}

export const CollapsedList: React.FC<CollapsedListProps> = ({
  title,
  children,
  counter,
  fullWidth,
  initialExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(initialExpanded);
  return (
    <Stack>
      <Stack
        onClick={() => setExpanded((e) => !e)}
        direction="row"
        justifyContent={fullWidth ? 'space-between' : undefined}
        alignItems="center"
        gap={1}
        sx={(theme) => ({
          cursor: 'pointer',
          '.icon, .title > .MuiTypography-root': {
            color: !!expanded ? theme.palette.primary.main : theme.palette.common.grey,
          },
          ':hover': {
            '.icon, .title > .MuiTypography-root': {
              color: theme.palette.primary.main,
            },
          },
        })}
      >
        <Box className="title">{title}</Box>

        {counter && <Counter> {counter}</Counter>}

        <IconButton
          className="icon"
          sx={(theme) => ({
            transform: !!expanded ? 'rotate(180deg)' : 'none',
            transition: theme.transitions.create(['all'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.short,
            }),
          })}
        >
          <DropdownIcon />
        </IconButton>
      </Stack>
      <Box>{expanded && children}</Box>
    </Stack>
  );
};
