import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { InformationIcon, TagSelect } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { SelectedCriteriaList } from '../../../../components/uikit-components/SelectedCriteriaList/SelectedCriteriaList.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getRouteModalPathname } from '../../../../helpers/misc';
import { getDisplayedCriteria } from '../../helpers';

export const MessagesModalPreviewSendToParents: FC = () => {
  const { parents, parentsCriteria, parentsByIndividualStudent, criteriaDate } = useMessage();
  const { formatMessage } = useIntl();

  return (
    <>
      {Boolean(parentsCriteria?.length || parentsByIndividualStudent?.length) && (
        <SelectedCriteriaList>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {getDisplayedCriteria({
              selectedCriteria: parentsCriteria,
              children: (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="h4" pr={1.75}>
                    <FormattedMessage id="messages-ParentsOfStudentsByCriteria" />
                  </Typography>

                  {!!criteriaDate && (
                    <Stack direction="row" alignItems="center" spacing={0.5} pr={1}>
                      <TagSelect
                        label={
                          <Typography variant="h3" color={(theme) => theme.palette.common.grey2}>
                            {format(new Date(criteriaDate), SHORT_FORMATTED_DATE_FORMAT_FNS)}
                          </Typography>
                        }
                        sx={(theme) => ({
                          '&.MuiChip-root': {
                            backgroundColor: theme.palette.background.paper,
                          },
                        })}
                      />

                      <Tooltip
                        title={formatMessage({ id: 'messages-ParentsOfStudentsPreviewTooltip' })}
                        placement="top"
                      >
                        <IconButton inverse sx={(theme) => ({ color: `inherit` })}>
                          <InformationIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
              ),
            })}
          </Stack>
          {
            /* Parents by individual students */
            Boolean(parentsByIndividualStudent?.length) && (
              <Stack direction="row" mt={1} alignItems="baseline" gap={1}>
                <Typography variant="h4">
                  <FormattedMessage id="messages-ParentsOfStudentsIndividually" />
                </Typography>

                {parentsByIndividualStudent.map((student, index, arr) => (
                  <>
                    <Link key={student.user_id} to={getRouteModalPathname('student', student)}>
                      <TagSelect
                        sx={(theme) => ({
                          '&.MuiChip-root': {
                            backgroundColor: theme.palette.background.paper,
                            color: 'common.grey2',
                          },
                        })}
                        color="default"
                        onClick={() => getRouteModalPathname('student', student)}
                        key={student.user_id}
                        label={getUserFullName(student)}
                      />
                    </Link>
                    {index < arr.length - 1 && (
                      <Typography variant="h3" color="common.grey2">
                        ,
                      </Typography>
                    )}
                  </>
                ))}
              </Stack>
            )
          }
        </SelectedCriteriaList>
      )}

      {
        /* Individual parents */
        Boolean(parents?.length) && (
          <Box>
            <Typography variant="h4">
              <FormattedMessage id="messages-ParentsOfStudentsIndividually" />
            </Typography>
            <Box>
              {parents.map((parent) => (
                <TagSelect
                  sx={(theme) => ({
                    '&.MuiChip-root': {
                      backgroundColor: theme.palette.background.paper,
                    },
                  })}
                  key={parent.user_id}
                  label={getUserFullName(parent)}
                />
              ))}
            </Box>
          </Box>
        )
      }
    </>
  );
};
