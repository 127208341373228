import { Box, Card, Icon, Stack, Typography } from '@mui/material';
import { GET_MESSAGE_QUERY, MAX_PAGE_SIZE, MessageType } from '@schooly/api';
import { RichTextRef } from '@schooly/components/form-rich-text';
import { ControlTextField } from '@schooly/components/form-text-field';
import { Attention2Icon, ModalContent, ModalMain, RadioGroupCard } from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, SubmitHandler } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { MessageForm } from '../../../context/messages/MessageContext';
import { useMessage } from '../../../context/messages/useMessage';
import {
  MESSAGE_MAX_CHARS_LENGTH,
  SUBJECT_MAX_CHARS_LENGTH,
} from '../../../context/messages/WithMessage';
import { isOnlyGroupAndDateFilled } from '../../../helpers/messages';
import { useSchool } from '../../../hooks/useSchool';
import { MessagesCloseModalConfirmation } from './confirmations/MessagesCloseModalConfirmation';
import { MessagesCreateModalDeleteConfirmation } from './confirmations/MessagesCreateModalDeleteConfirmation';
import { MessagesDeleteGroupModal } from './confirmations/MessagesDeleteGroupModal';
import { MessagesCreateHeader } from './MessagesCreateHeader';
import { MessagesCreateModalFooter } from './MessagesCreateModalFooter';
import { MessagesCreateModalMessage } from './MessagesCreateModalMessage/MessagesCreateModalMessage';
import { MessagesCreateModalRecipients } from './MessagesCreateModalRecipients/MessagesCreateModalRecipients';
import { MessagesCreateModalRemoveArchived } from './MessagesCreateModalRemoveArchived';

export const MessagesCreateContent: FC = () => {
  const { formatMessage } = useIntl();
  const { schoolId } = useSchool();
  const {
    canEdit,
    isSubmitted,
    form,
    actions,
    consentForm,
    consentFormShowed,
    showDeprecatedRecipientsWarning,
    prevCriteriaDate,
    isRemoveGroupDialogOpen,
    notActualGroups,
    submitAction,
  } = useMessage();

  const queryClient = useQueryClient();

  const [shouldValidateForPublish, setShouldValidateForPublish] = useState(false);
  const [shouldValidateForSave, setShouldValidateForSave] = useState(false);

  const [toolbarVisible, setToolbarVisible] = useState(true);
  const toolbarRef = useRef<RichTextRef>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const modalHeaderRef = useRef<HTMLDivElement>(null);

  const groupsChecked = useRef(false);

  const criteriaDate = form.watch('criteria_date');
  const criteriaSubject = form.watch('title');
  const parentCriteria = form.watch('parents_of_students_criteria');
  const staffCriteria = form.watch('staff_criteria');

  const subjectLength = criteriaSubject.length;
  const maxLengthError = subjectLength > SUBJECT_MAX_CHARS_LENGTH;

  const allGroupsInvalid = useMemo(
    () =>
      notActualGroups.length ===
      new Set([...(parentCriteria.group || []), ...(staffCriteria.group || [])]).size,
    [notActualGroups, parentCriteria, staffCriteria],
  );

  const onlyGroupsSelected = useMemo(() => {
    const parentsValid = isOnlyGroupAndDateFilled(parentCriteria);
    const staffValid = isOnlyGroupAndDateFilled(staffCriteria);

    return parentsValid && staffValid;
  }, [parentCriteria, staffCriteria]);

  useEffect(() => {
    if (prevCriteriaDate !== criteriaDate || !groupsChecked.current) {
      if (!groupsChecked.current) {
        groupsChecked.current = true;
      }

      actions.checkInvalidGroups({
        schoolId: schoolId || '',
        singleDate: criteriaDate,
        pageSize: MAX_PAGE_SIZE,
        min: 1,
      });
    }
  }, [actions, schoolId, criteriaDate, prevCriteriaDate, parentCriteria]);

  useEffect(() => {
    const toolbarContentRef = toolbarRef.current?.contentRef().current;
    const toolbarWrapperRef = toolbarRef.current?.wrapperRef().current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!toolbarContentRef || !toolbarWrapperRef || !modalHeaderRef) {
          return;
        }

        setToolbarVisible(entry.isIntersecting);

        if (!entry.isIntersecting && toolbarContentRef) {
          modalHeaderRef.current?.appendChild(toolbarContentRef);
        } else if (entry.isIntersecting && toolbarWrapperRef && toolbarContentRef) {
          toolbarWrapperRef.appendChild(toolbarContentRef);
        }
      },
      {
        root: modalContentRef.current,
      },
    );

    if (toolbarWrapperRef) {
      observer.observe(toolbarWrapperRef);
    }
  }, [actions]);

  const onSubmit = useCallback<SubmitHandler<MessageForm>>(
    async (d, e) => {
      if (d.body.length > MESSAGE_MAX_CHARS_LENGTH) return;

      const nativeEvent = e?.nativeEvent;
      const publishAction = Boolean(
        nativeEvent instanceof SubmitEvent && nativeEvent.submitter?.hasAttribute('data-publish'),
      );
      const submitAction = Boolean(
        nativeEvent instanceof SubmitEvent && nativeEvent.submitter?.hasAttribute('data-submit'),
      );
      const saveAction = Boolean(
        nativeEvent instanceof SubmitEvent && nativeEvent.submitter?.hasAttribute('data-save'),
      );

      const changeStatusAction = publishAction || submitAction;

      const submitForm = async () => {
        const consentFormPayload =
          consentFormShowed && consentForm ? consentForm.getValues() : undefined;

        const formData = form.getValues();
        let res;

        if (publishAction) {
          res = await actions.saveAndPublishMessage({
            formData,
            concentFormData: consentFormPayload,
          });
        } else if (submitAction) {
          res = await actions.saveAndSubmitMessage({
            formData,
            concentFormData: consentFormPayload,
          });
        } else {
          res = await actions.saveMessage({ formData, concentFormData: consentFormPayload });
        }

        if (res) {
          queryClient.invalidateQueries([GET_MESSAGE_QUERY]);
          actions.onClose();
        }
      };

      actions.setSubmitAction(() => {
        return submitForm;
      });

      setShouldValidateForPublish(changeStatusAction || isSubmitted);
      setShouldValidateForSave(saveAction);

      const isValid = await form.trigger();
      if (!isValid) return;

      if (consentFormShowed && consentForm) {
        const isValid = await consentForm.trigger();
        if (!isValid) return;
      }

      if (notActualGroups.length && changeStatusAction) {
        actions.showRemoveGroupDialog();
        return;
      } else {
        submitForm();
      }
    },
    [actions, consentForm, consentFormShowed, form, isSubmitted, notActualGroups, queryClient],
  );

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      const fieldsToValidateForPublish = [
        'body',
        'staff_criteria',
        'staff_ids',
        'parents_of_students_ids',
        'parents_of_students_criteria',
      ];

      if (name && fieldsToValidateForPublish.includes(name)) {
        setShouldValidateForPublish(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [form, parentCriteria, staffCriteria, formatMessage]);

  useEffect(() => {
    return form.reset;
  }, [form]);

  if (!canEdit) {
    return <ModalAccessDenied />;
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e) => {
          setShouldValidateForPublish(false);
          setShouldValidateForSave(false);
          form.handleSubmit(onSubmit)(e);
        }}
      >
        <MessagesCreateHeader ref={modalHeaderRef} showStickyToolbar={!toolbarVisible} />

        <ModalMain>
          <ModalContent active ref={modalContentRef}>
            <Stack mb={2.5} gap={1}>
              <Typography variant="h2">{formatMessage({ id: 'messages-MessageType' })}</Typography>
              <Stack direction="row" gap={1}>
                <Controller
                  control={form.control}
                  name="type"
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <RadioGroupCard
                        {...field}
                        labelTextId="messages-Announcement"
                        checked={field.value === MessageType.Announcement}
                        onChange={() => {
                          field.onChange(MessageType.Announcement);
                        }}
                      >
                        <Typography>
                          {formatMessage({ id: 'messages-AnnouncementDescription' })}
                        </Typography>
                      </RadioGroupCard>
                    );
                  }}
                />

                <Controller
                  control={form.control}
                  name="type"
                  render={({ field }) => {
                    return (
                      <RadioGroupCard
                        {...field}
                        labelTextId="messages-Thread"
                        checked={field.value === MessageType.Thread}
                        onChange={() => {
                          field.onChange(MessageType.Thread);
                        }}
                      >
                        <Typography>
                          {formatMessage({ id: 'messages-ThreadDescription' })}
                        </Typography>
                      </RadioGroupCard>
                    );
                  }}
                />
              </Stack>
            </Stack>

            {/* TODO: remove when there are no messages with deprecated staff_of_students_ids and parents_ids recipients */}
            {showDeprecatedRecipientsWarning && (
              <Box mb={2.5}>
                <OutdatedRecipientsWarning
                  warning={
                    <Typography>
                      {formatMessage({ id: 'messages-OutdatedRecipientsWarning' })}
                    </Typography>
                  }
                />
              </Box>
            )}

            <MessagesCreateModalRecipients shouldValidate={shouldValidateForPublish} />

            <Box mt={4}>
              <ControlTextField
                name="title"
                control={form.control}
                required
                label={formatMessage({ id: 'messages-Subject' })}
                fullWidth
                error={maxLengthError}
                rules={{
                  validate: (v) => {
                    if (!shouldValidateForPublish && !shouldValidateForSave) {
                      return true;
                    }
                    return v.length > 0 ? true : formatMessage({ id: 'input-ErrorRequired' });
                  },
                }}
              />

              <Typography
                variant="caption"
                textAlign="right"
                display="block"
                pr={1.5}
                py={0.25}
                color={maxLengthError ? 'error.main' : 'common.grey'}
              >
                {subjectLength + '/' + SUBJECT_MAX_CHARS_LENGTH}
              </Typography>
            </Box>

            <MessagesCreateModalMessage
              ref={toolbarRef}
              shouldValidate={shouldValidateForPublish && !shouldValidateForSave}
              toolbarReplaced={!toolbarVisible}
            />
          </ModalContent>
        </ModalMain>
        <MessagesCreateModalFooter />

        <MessagesCloseModalConfirmation />
        <MessagesCreateModalDeleteConfirmation />
        <MessagesCreateModalRemoveArchived />
        <MessagesDeleteGroupModal
          isOpen={isRemoveGroupDialogOpen}
          invalidGroups={notActualGroups}
          allInvalid={allGroupsInvalid && onlyGroupsSelected}
          onClose={actions.handleCloseRemoveGroupDialog}
          onConfirm={() => actions.handleConfirmRemoveGroupDialog(submitAction)}
          onEdit={actions.handleEditRemoveGroupDialog}
          allInvalidHideCancel={allGroupsInvalid && onlyGroupsSelected}
        />
      </form>
    </FormProvider>
  );
};

const OutdatedRecipientsWarning: FC<{ warning: ReactNode }> = ({ warning }) => {
  return (
    <Card
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 2,
        p: 2,
        borderColor: theme.palette.common.orange,
        bgcolor: theme.palette.common.orange5,
        color: theme.palette.common.orange,
        '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
      })}
    >
      <Icon>
        <Attention2Icon />
      </Icon>

      {warning}
    </Card>
  );
};
