import {
  ApiError,
  DEFAULT_DATE_FORMAT_FNS,
  GET_MESSAGE_QUERY,
  getAttachment,
  IListGroupArguments,
  listGroups,
  MessageAction,
  MessageStatus,
  MessageType,
  MessageUpdate,
  OutcomingAttachment,
  useCreateConsentFormMutation,
  useCreateMessageMutation,
  useGetMessageQuery,
  useGetSchoolPropertiesQuery,
  useMessageActionMutation,
  useRemoveMessage,
  useUpdateMessageMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { NotificationActions, useNotifications } from '@schooly/components/notifications';
import { usePrevious } from '@schooly/hooks/use-previous';
import { useAgeGroups, useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { useQueryClient } from '@tanstack/react-query';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { format } from 'date-fns';
import { FC, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { useForm as useReactHookForm } from 'react-hook-form-lts';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CreateConsentForm } from '../../components/common/ConsentForms/ConsentForm';
import { AttachmentFile } from '../../components/ui/Input/RichText/Attachments/attachment-types';
import {
  getAttachmentName,
  getBase64Href,
} from '../../components/ui/Input/RichText/Attachments/utils';
import { SchoolUserRole } from '../../constants/school';
import { getMessagePublishDate } from '../../helpers/messages';
import useConvertFileToBuffer from '../../hooks/useConvertFileToBuffer';
import useFlag from '../../hooks/useFlag';
import {
  convertIndividualToUserSearchResult,
  getCriteriaForMessageForm,
  getIndividualIdsForMessageForm,
  getParentsFromIndividuals,
  getParentsOfStudentsFromIndividuals,
  getStaffAvailableCriteria,
  getStaffFromIndividuals,
  getStaffOfStudentsFromIndividuals,
  getStudentsAvailableCriteria,
} from '../../pages/Messages/helpers';
import { convertApiTimestamp } from '../../utils/convertApiTimestamp';
import IntlError from '../../utils/intlError';
import { useRouter } from '../router/useRouter';
import {
  MessageContext,
  MessageContextProps,
  MessageForm,
  SaveMessageParams,
} from './MessageContext';

export interface WithMessageProps extends PropsWithChildren {}

export const MESSAGE_MAX_CHARS_LENGTH = 5000;
export const MESSAGE_CREATE_GROUP_ID_SEARCH_PARAM = 'group_id';
export const SUBJECT_MAX_CHARS_LENGTH = 200;

export const defaultValues: Omit<MessageForm, 'id'> = {
  title: '',
  body: '',
  attachments: [],
  type: MessageType.Announcement,
  parents_of_students_criteria: {},
  staff_criteria: {},
  parents_of_students_ids: [],
  staff_ids: [],
};

export const WithMessage: FC<WithMessageProps> = ({ children }) => {
  const { id } = useParams<'id'>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { goBack, closeAndClean } = useRouter();
  const { showError, showNotification } = useNotifications();

  const invalidateQueries = useInvalidateListQueriesFor('message');
  const queryClient = useQueryClient();

  const createMessage = useCreateMessageMutation();
  const updateMessage = useUpdateMessageMutation();
  const removeMessage = useRemoveMessage();
  const createConsentForm = useCreateConsentFormMutation();
  const messageActionMutation = useMessageActionMutation();

  const messageId = id === 'new' ? undefined : id;

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [fetchingAttachments, setFetchingAttachments] = useState<
    MessageContextProps['fetchingAttachments']
  >({});
  const [isPublishInProgress, setIsPublishInProgress] = useState(false);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [isUnsubmitInProgress, setIsUnsubmitInProgress] = useState(false);

  const [isCheckGroupsInProgress, setIsCheckGroupsInProgress] = useState(false);

  const [notActualGroups, setNotActualGroups] = useState<string[]>([]);

  const [isRemoveGroupDialogOpen, showRemoveGroupDialog, hideRemoveGroupDialog] = useFlag();
  const [deletingConfirmation, setDeletingConfirmation] = useState(false);

  const [isCloseModalDialogOpen, showCloseModalDialog, hideCloseModalDialog] = useFlag();

  const [consentFormShowed, showConsentForm, hideConsentForm] = useFlag();

  const [submitAction, setSubmitAction] = useState<() => Promise<void>>(async () => {});

  const { currentUser, permissions, schoolId = '' } = useAuth();

  const paramsStudentGroupId = searchParams.get(MESSAGE_CREATE_GROUP_ID_SEARCH_PARAM);

  const { data: messageData, isFetching: fetchingMessage } = useGetMessageQuery(
    { messageId: messageId ?? '' },
    { enabled: Boolean(messageId), refetchOnMount: 'always' },
  );

  const { subjects: groupSubjects, data: subjectsData } = useSubjects(
    { schoolId },
    { refetchOnMount: 'always' },
  );

  const { data: studentProperties } = useGetSchoolPropertiesQuery({
    userType: SchoolUserRole.Student,
    schoolId,
  });
  const { data: staffProperties } = useGetSchoolPropertiesQuery({
    userType: SchoolUserRole.Staff,
    schoolId,
  });
  const { activePropertiesMap: studentActivePropertiesMap } = useSchoolProperties({
    userType: SchoolUserRole.Student,
    schoolId,
  });

  const properties = useMemo(() => {
    const allProps = [
      ...(studentProperties?.school_properties ?? []),
      ...(staffProperties?.school_properties ?? []),
    ];

    return allProps.filter((item, index) => {
      const currIndex = allProps.findIndex((i) => i.id === item.id);
      return currIndex === index;
    });
  }, [staffProperties?.school_properties, studentProperties?.school_properties]);

  const { ageGroups: staffAgeGroups, schoolLevels: staffSchoolLevels } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Staff,
  });
  const { ageGroups: studentAgeGroups, schoolLevels: studentSchoolLevels } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const messageCreateDefaultValues = useMemo(() => {
    if (!paramsStudentGroupId)
      return {
        ...defaultValues,
        parents_of_students_criteria: {
          status: studentActivePropertiesMap.status
            .filter((status) => status.name === 'Enrolled')
            .map((status) => status.id),
        },
      };

    return {
      ...defaultValues,
      parents_of_students_criteria: {
        ...defaultValues.parents_of_students_criteria,
        group: [paramsStudentGroupId],
      },
    };
  }, [paramsStudentGroupId, studentActivePropertiesMap.status]);

  const {
    staffIndividuals,
    studentIndividuals,
    staff,
    actualParents,
    actualStaff,
    parents,
    staffByIndividualStudent,
    parentsByIndividualStudent,
  } = useMemo(() => {
    const staffIndividuals = messageData
      ? getStaffFromIndividuals(messageData.message_individuals) ?? []
      : [];
    const staff = staffIndividuals.map(convertIndividualToUserSearchResult);

    const studentIndividuals = messageData
      ? getParentsOfStudentsFromIndividuals(messageData.message_individuals) ?? []
      : [];
    const parentsByIndividualStudent = studentIndividuals.map(convertIndividualToUserSearchResult);

    const actualParents =
      messageData?.message_recipients.filter(
        (recipient) => recipient.school_user_relation_role === SchoolUserRole.Parent,
      ) ?? [];

    const actualStaff =
      messageData?.message_recipients.filter(
        (recipient) => recipient.school_user_relation_role === SchoolUserRole.Staff,
      ) ?? [];

    // Individual parent recipients are deprecated
    //This logic should be removed as soon as there are no messages with such recipients
    const parents =
      getParentsFromIndividuals(messageData?.message_individuals)?.map(
        convertIndividualToUserSearchResult,
      ) ?? [];

    // Staff by individual student recipients are deprecated
    //This logic should be removed as soon as there are no messages with such recipients
    const staffByIndividualStudent =
      getStaffOfStudentsFromIndividuals(messageData?.message_individuals)?.map(
        convertIndividualToUserSearchResult,
      ) ?? [];

    return {
      staffIndividuals,
      studentIndividuals,
      staff,
      actualParents,
      actualStaff,
      parents,
      staffByIndividualStudent,
      parentsByIndividualStudent,
    };
  }, [messageData]);

  const { parentsCriteria, staffCriteria } = useMemo(() => {
    const parentsCriteria = messageData
      ? getStudentsAvailableCriteria({
          criteria: messageData.message_criteria,
          properties,
          subjects: groupSubjects,
          ageGroups: studentAgeGroups,
          schoolLevels: studentSchoolLevels,
        }) ?? []
      : [];

    const staffCriteria = messageData
      ? getStaffAvailableCriteria({
          criteria: messageData.message_criteria,
          properties,
          subjects: groupSubjects,
          ageGroups: staffAgeGroups,
          schoolLevels: staffSchoolLevels,
        }) ?? []
      : [];

    return { staffCriteria, parentsCriteria };
  }, [
    groupSubjects,
    messageData,
    properties,
    staffAgeGroups,
    staffSchoolLevels,
    studentAgeGroups,
    studentSchoolLevels,
  ]);

  const { publishDate, criteriaDate } = useMemo(() => {
    const criteriaDateTimestamp = convertApiTimestamp(messageData?.message.criteria_date);
    const criteriaDate = criteriaDateTimestamp
      ? format(criteriaDateTimestamp, DEFAULT_DATE_FORMAT_FNS)
      : undefined;

    const publishDate = getMessagePublishDate(messageData?.message);

    return { publishDate, criteriaDate };
  }, [messageData?.message]);

  const messageFormFields = useMemo<MessageForm>(() => {
    return {
      title: messageData?.message.title || '',
      body: messageData?.message.body || '',
      attachments: messageData?.message.attachments ?? [],
      criteria_date: criteriaDate,
      staff_ids: getIndividualIdsForMessageForm(staffIndividuals),
      parents_of_students_ids: getIndividualIdsForMessageForm(studentIndividuals),
      parents_of_students_criteria: {
        ...getCriteriaForMessageForm(parentsCriteria),
        date: criteriaDate ? [criteriaDate] : undefined,
      },
      type: messageData?.message.type || MessageType.Announcement,
      staff_criteria: getCriteriaForMessageForm(staffCriteria),
    };
  }, [
    messageData,
    criteriaDate,
    staffIndividuals,
    studentIndividuals,
    parentsCriteria,
    staffCriteria,
  ]);

  const messageForm = useReactHookForm<MessageForm>({
    defaultValues: messageData ? messageFormFields : messageCreateDefaultValues,
  });

  const showDeprecatedRecipientsWarning = useMemo(
    () =>
      !!parents.length ||
      !!staffByIndividualStudent.length ||
      !!messageFormFields.staff_criteria.gender?.length ||
      !!messageFormFields.staff_criteria.nationality?.length,
    [parents, staffByIndividualStudent, messageFormFields],
  );

  const formCriteriaDate = messageForm.watch('criteria_date');
  const formParentsCriteria = messageForm.watch('parents_of_students_criteria');
  const formStaffCriteria = messageForm.watch('staff_criteria');

  const prevCriteriaDate = usePrevious(formCriteriaDate);
  const previousCriteriaDate = useRef(formCriteriaDate);
  const updatePrevCriteriaDate = (date: string) => (previousCriteriaDate.current = date);

  const consentForm = useReactHookForm<CreateConsentForm>({
    defaultValues: {
      description: messageData?.message.consent_form
        ? messageData?.message.consent_form.description
        : '',
    },
    mode: 'onChange',
  });

  const hasViewerPermission = permissions.includes('message_viewer');
  const hasCreatorPermission = permissions.includes('message_creator');
  const hasPublisherPermission = permissions.includes('message_publisher');

  const isPublished = messageData?.message.status === MessageStatus.Published;
  const isDraft = messageData?.message.status === MessageStatus.Draft;
  const isSubmitted = messageData?.message.status === MessageStatus.Submitted;
  const isCreator = messageData?.message.creator?.user_id === currentUser?.user_id;

  const canEdit = useMemo(() => {
    const creatorPermissions = !isPublished && isCreator && hasCreatorPermission;
    const publisherPermissions = !isPublished && hasPublisherPermission;

    return messageId ? creatorPermissions || publisherPermissions : hasCreatorPermission;
  }, [isPublished, isCreator, hasCreatorPermission, hasPublisherPermission, messageId]);

  const canPublish = useMemo(
    () => !isPublished && hasPublisherPermission,
    [hasPublisherPermission, isPublished],
  );

  const canSubmit = useMemo(
    () =>
      !isSubmitted && (isCreator || !messageId) && hasCreatorPermission && !hasPublisherPermission,
    [hasCreatorPermission, hasPublisherPermission, isCreator, isSubmitted, messageId],
  );

  const canUnsubmit = useMemo(() => {
    const creatorPermissions = isSubmitted && isCreator && hasCreatorPermission;
    const publisherPermissions = isSubmitted && hasPublisherPermission;

    return creatorPermissions || publisherPermissions;
  }, [hasCreatorPermission, hasPublisherPermission, isCreator, isSubmitted]);

  const hasAccessToThreads = hasPublisherPermission || isCreator;

  const bodyLength = messageForm.watch('body')?.length || 0;
  const isBodyLengthOverLimit = bodyLength > MESSAGE_MAX_CHARS_LENGTH;

  const setFetchingAttachmentId = useCallback(
    (attachmentId: string) => {
      setFetchingAttachments({ ...fetchingAttachments, [attachmentId]: true });
    },
    [fetchingAttachments],
  );

  const removeLoadingAttachmentId = useCallback(
    (attachmentId: string) => {
      setFetchingAttachments({ ...fetchingAttachments, [attachmentId]: false });
    },
    [fetchingAttachments],
  );

  const fetchAttachment = useCallback<MessageContextProps['actions']['fetchAttachment']>(
    async (attachmentId) => {
      if (!messageId || !attachmentId) return;

      setFetchingAttachmentId(attachmentId);

      try {
        const data = await getAttachment(attachmentId, messageId);
        triggerBase64Download(getBase64Href(data), getAttachmentName(data));
      } catch (err) {
        showError(err as ApiError);
      } finally {
        removeLoadingAttachmentId(attachmentId);
      }
    },
    [messageId, removeLoadingAttachmentId, setFetchingAttachmentId, showError],
  );

  const handlePreview = useCallback<MessageContextProps['actions']['handlePreview']>(
    (id) => {
      navigate(`/messages/${id}`, { state: { replace: true } });
    },
    [navigate],
  );

  const formAttachments = messageForm.watch('attachments');
  const convertedFiles = useConvertFileToBuffer(formAttachments);

  const saveMessage = useCallback<
    ({
      formData,
      concentFormData,
      notify,
    }: SaveMessageParams & { notify?: boolean }) => Promise<string | undefined>
  >(
    async ({ formData, concentFormData, notify = true }) => {
      if (!schoolId) {
        const error = new IntlError('error-NoSchoolId');
        showError(error);
        throw error;
      }

      const { staff_criteria, parents_of_students_criteria } = formData;

      const staffSchoolPropertyIds = [
        ...(staff_criteria.status ?? []),
        ...(staff_criteria.age_group ?? []),
        ...(staff_criteria.house ?? []),
        ...(staff_criteria.department ?? []),
      ];

      const studentSchoolPropertyIds = [
        ...(parents_of_students_criteria.status ?? []),
        ...(parents_of_students_criteria.age_group ?? []),
        ...(parents_of_students_criteria.house ?? []),
      ];

      const data: MessageUpdate = {
        ...formData,
        school_id: schoolId,
        parents_of_students_criteria: {
          school_property_ids: studentSchoolPropertyIds,
          genders: parents_of_students_criteria.gender ?? [],
          nationalities: parents_of_students_criteria.nationality ?? [],
          groups: parents_of_students_criteria.group ?? [],
          subjects: parents_of_students_criteria.subject ?? [],
        },
        staff_criteria: {
          school_property_ids: staffSchoolPropertyIds,
          groups: staff_criteria.group ?? [],
          subjects: staff_criteria.subject ?? [],
          genders: [],
          nationalities: [],
        },
        attachments: convertedFiles as OutcomingAttachment[],
      };

      const modifiedData = Object.fromEntries(
        Object.entries(data).filter(([k]) => k !== 'school_id'),
      ) as Omit<MessageUpdate, 'school_id'>;

      modifiedData.attachment_ids_to_save = (formAttachments as AttachmentFile[])
        ?.filter((f) => !f.file)
        .map((f) => f.id);

      setSaving(true);

      const onSuccess = async (id: string, successTextId: string) => {
        concentFormData &&
          (await createConsentForm.mutateAsync(
            {
              reference_id: id,
              reference_type: 'message',
              description: concentFormData.description,
            },
            { onError: showError },
          ));

        if (consentFormShowed) hideConsentForm();

        notify && showNotification({ textId: successTextId, type: 'success' });

        invalidateQueries();
      };
      try {
        if (messageId) {
          const res = await updateMessage.mutateAsync(
            { messageId, data: modifiedData },
            {
              onSuccess: async () => {
                await onSuccess(messageId, 'messages-UpdateSuccess');
              },
            },
          );
          return res ? messageId : undefined;
        } else {
          const res = await createMessage.mutateAsync(data, {
            onSuccess: async ({ id }) => {
              await onSuccess(id, 'messages-CreateSuccess');
            },
          });
          return res.id;
        }
      } catch (err) {
        showError(err as ApiError);
      } finally {
        setSaving(false);
      }
    },
    [
      schoolId,
      convertedFiles,
      formAttachments,
      showError,
      createConsentForm,
      consentFormShowed,
      hideConsentForm,
      showNotification,
      invalidateQueries,
      messageId,
      updateMessage,
      createMessage,
    ],
  );

  const deleteMessage = useCallback<MessageContextProps['actions']['deleteMessage']>(async () => {
    if (!messageId) {
      return;
    }
    setDeletingConfirmation(false);
    setDeleting(true);

    try {
      const response = await removeMessage.mutateAsync(messageId);

      showNotification({ textId: 'messages-DeleteSuccess', type: 'success' });
      setDeleting(false);

      invalidateQueries();

      closeAndClean();

      return response;
    } catch (err) {
      showError(err as ApiError);
      setDeleting(false);
    }
  }, [messageId, removeMessage, showNotification, invalidateQueries, closeAndClean, showError]);

  const cancelDeletingConfirmation = useCallback<
    MessageContextProps['actions']['cancelDeletingConfirmation']
  >(() => {
    setDeletingConfirmation(false);
  }, [setDeletingConfirmation]);

  const sendAction = useCallback(
    async (actionType: MessageAction, messageId?: string) => {
      if (!messageId) {
        return;
      }

      let successMessageId;
      switch (actionType) {
        case MessageAction.Publish:
          setIsPublishInProgress(true);
          successMessageId = 'messages-PublishSuccess';
          break;
        case MessageAction.Submit:
          setIsSubmitInProgress(true);
          successMessageId = 'messages-SubmitSuccess';
          break;
        case MessageAction.Unsubmit:
          setIsUnsubmitInProgress(true);
          successMessageId = 'messages-UnsubmitSuccess';
          break;
        default:
          break;
      }

      const notificationActions: NotificationActions | undefined =
        actionType === MessageAction.Submit
          ? [
              {
                textId: 'messages-ViewMessage',
                handler: () => navigate(`/messages/${messageId}`),
                buttonColor: 'light',
              },
              {
                textId: 'messages-Unsubmit',
                handler: async () => {
                  try {
                    await messageActionMutation.mutateAsync({
                      messageId,
                      action: MessageAction.Unsubmit,
                    });

                    queryClient.invalidateQueries([GET_MESSAGE_QUERY, { messageId }]);
                    invalidateQueries();
                  } catch (error) {
                    showNotification({
                      message: (error as ApiError).reason,
                      type: 'error',
                    });
                  }
                },
                isOutline: true,
                buttonColor: 'white-text',
              },
            ]
          : undefined;

      try {
        await messageActionMutation.mutateAsync({
          messageId,
          action: actionType,
        });

        showNotification({
          type: 'success',
          actions: notificationActions,
          textId: successMessageId,
        });

        queryClient.invalidateQueries([GET_MESSAGE_QUERY, { messageId }]);
        invalidateQueries();
      } catch (err) {
        showError(err as ApiError | IntlError);
      } finally {
        setIsPublishInProgress(false);
        setIsSubmitInProgress(false);
        setIsUnsubmitInProgress(false);
      }
    },
    [navigate, messageActionMutation, queryClient, invalidateQueries, showNotification, showError],
  );

  const saveAndPublishMessage = useCallback<
    MessageContextProps['actions']['saveAndPublishMessage']
  >(
    async (props) => {
      setIsPublishInProgress(true);
      const id = await saveMessage({ ...props, notify: false });
      if (id) {
        await sendAction(MessageAction.Publish, id);
        return id;
      }
    },
    [saveMessage, sendAction],
  );

  const saveAndSubmitMessage = useCallback<MessageContextProps['actions']['saveAndSubmitMessage']>(
    async (props) => {
      setIsSubmitInProgress(true);
      const id = await saveMessage({ ...props, notify: false });
      if (id) {
        await sendAction(MessageAction.Submit, id);
        return id;
      }
    },
    [saveMessage, sendAction],
  );

  const publishMessage = useCallback(() => {
    if (notActualGroups.length) {
      setSubmitAction(() => {
        return async () => {
          const consentFormPayload =
            consentFormShowed && consentForm ? consentForm.getValues() : undefined;

          await saveAndPublishMessage({
            formData: messageForm.getValues(),
            concentFormData: consentFormPayload,
          });
          return;
        };
      });
      return Promise.resolve(showRemoveGroupDialog());
    } else {
      return sendAction(MessageAction.Publish, messageId);
    }
  }, [
    messageId,
    sendAction,
    notActualGroups,
    showRemoveGroupDialog,
    consentForm,
    consentFormShowed,
    messageForm,
    saveAndPublishMessage,
  ]);

  const submitMessage = useCallback(() => {
    if (notActualGroups.length) {
      setSubmitAction(() => {
        return async () => {
          const consentFormPayload =
            consentFormShowed && consentForm ? consentForm.getValues() : undefined;

          await saveAndSubmitMessage({
            formData: messageForm.getValues(),
            concentFormData: consentFormPayload,
          });
          return;
        };
      });
      return Promise.resolve(showRemoveGroupDialog());
    } else {
      return sendAction(MessageAction.Submit, messageId);
    }
  }, [
    messageId,
    sendAction,
    notActualGroups,
    showRemoveGroupDialog,
    consentForm,
    consentFormShowed,
    messageForm,
    saveAndSubmitMessage,
  ]);

  const unsubmitMessage = useCallback(() => {
    return sendAction(MessageAction.Unsubmit, messageId);
  }, [messageId, sendAction]);

  const checkInvalidGroups = useCallback(
    async (params: IListGroupArguments, previewMode = false) => {
      setNotActualGroups([]);
      setIsCheckGroupsInProgress(true);

      try {
        const { results: groups } = await listGroups(params);

        const parentGroups =
          (previewMode
            ? messageFormFields.parents_of_students_criteria.group
            : formParentsCriteria.group) || [];
        const staffGroups =
          (previewMode ? messageFormFields.staff_criteria.group : formStaffCriteria.group) || [];

        const currentCriteria = [...parentGroups, ...staffGroups].reduce((acc, next) => {
          return [...acc.filter((groupId) => groupId !== next), next];
        }, [] as string[]);

        const notActualGroupIds = currentCriteria.filter(
          (groupId) => !groups.some((g) => g.id === groupId),
        );

        setNotActualGroups(notActualGroupIds);
      } catch (error) {
        console.error(error);
      } finally {
        setIsCheckGroupsInProgress(false);
      }
    },
    [formParentsCriteria, formStaffCriteria, messageFormFields],
  );

  const removeNotActualCriteriaGroups = useCallback(
    (toDeleteCriteriaGroups: string[]) => {
      messageForm.setValue(
        'parents_of_students_criteria.group',
        formParentsCriteria.group?.filter((groupId) => !toDeleteCriteriaGroups.includes(groupId)),
      );
      messageForm.setValue(
        'staff_criteria.group',
        formStaffCriteria.group?.filter((groupId) => !toDeleteCriteriaGroups.includes(groupId)),
      );
    },
    [messageForm, formParentsCriteria, formStaffCriteria],
  );

  const handleConfirmRemoveGroupDialog = useCallback(
    async (cb?: () => Promise<void>) => {
      removeNotActualCriteriaGroups(notActualGroups);
      setNotActualGroups([]);
      hideRemoveGroupDialog();

      await cb?.();
    },
    [hideRemoveGroupDialog, notActualGroups, removeNotActualCriteriaGroups],
  );

  const handleCloseRemoveGroupDialog = useCallback(() => {
    hideRemoveGroupDialog();
  }, [hideRemoveGroupDialog]);

  const handleEditRemoveGroupDialog = useCallback(() => {
    hideRemoveGroupDialog();
    messageForm.reset(messageFormFields);
    navigate(messageId ? `/messages/${messageId}/edit` : '/messages/new');
  }, [hideRemoveGroupDialog, messageId, messageForm, messageFormFields, navigate]);

  const handleHideConsentForm = useCallback(() => {
    consentForm.reset();
    hideConsentForm();
  }, [consentForm, hideConsentForm]);

  const onCloseCreateModal = useCallback(() => {
    if (messageForm.formState.isDirty) {
      showCloseModalDialog();
    } else {
      goBack();
    }
  }, [messageForm.formState.isDirty, showCloseModalDialog, goBack]);

  const value = {
    form: messageForm,

    fetching: fetchingMessage || !studentProperties || !subjectsData?.subjects,
    fetchingAttachments,

    messageId,

    canView: hasViewerPermission,
    canEdit,
    canPublish,
    canSubmit,
    canUnsubmit,

    isPublished,
    isDraft,
    isSubmitted,
    isCreator,

    isPublishInProgress,
    isSubmitInProgress,
    isUnsubmitInProgress,
    saving,
    deleting,

    isRemoveGroupDialogOpen,
    deletingConfirmation,

    isBodyLengthOverLimit,
    notActualGroups,

    consentForm,
    consentFormShowed,

    messageData,

    title: messageData?.message.title ?? '',
    body: messageData?.message.body ?? '',
    status: messageData?.message.status,
    creator: messageData?.message.creator,
    publisher: messageData?.message.publisher,
    linkedConsentForm: messageData?.message.consent_form,
    hasThreads: messageData?.message.type === MessageType.Thread && isPublished,
    hasAccessToThreads,
    attachments: messageData?.message.attachments || [],
    parentsCriteria,
    staffCriteria,
    criteriaDate,
    staff,
    actualParents,
    actualStaff,
    parents,
    staffByIndividualStudent,
    parentsByIndividualStudent,
    publishDate,
    showDeprecatedRecipientsWarning,

    messageFormFields,

    prevCriteriaDate: prevCriteriaDate ?? undefined,
    previousCriteriaDate: previousCriteriaDate.current ?? undefined,

    isCheckGroupsInProgress,
    submitAction,
    isCloseModalDialogOpen,

    actions: {
      fetchAttachment,
      handleClose: goBack,
      handlePreview,
      saveMessage,
      deleteMessage,
      setDeletingConfirmation,
      cancelDeletingConfirmation,
      publishMessage,
      submitMessage,
      unsubmitMessage,
      checkInvalidGroups,
      handleCloseRemoveGroupDialog,
      handleConfirmRemoveGroupDialog,
      showConsentForm,
      hideConsentForm: handleHideConsentForm,
      onClose: goBack,
      saveAndPublishMessage,
      saveAndSubmitMessage,
      updatePrevCriteriaDate,
      handleEditRemoveGroupDialog,
      showRemoveGroupDialog,
      setSubmitAction,
      showCloseModalDialog,
      hideCloseModalDialog,
      onCloseCreateModal,
      setNotActualGroups,
    },
  };

  return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
};
