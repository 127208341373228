import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4.5C2 3.67157 2.67157 3 3.5 3H12.5C13.3284 3 14 3.67157 14 4.5V10.5C14 11.3284 13.3284 12 12.5 12H8L4.8 14.4C4.47038 14.6472 4 14.412 4 14V12H3.5C2.67157 12 2 11.3284 2 10.5V4.5ZM5 6.5C5 6.22386 5.22386 6 5.5 6H10.5C10.7761 6 11 6.22386 11 6.5C11 6.77614 10.7761 7 10.5 7H5.5C5.22386 7 5 6.77614 5 6.5ZM5 8.5C5 8.22386 5.22386 8 5.5 8H8.5C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9H5.5C5.22386 9 5 8.77614 5 8.5Z"
      fill="#B3BBCB"
    />
    <path
      d="M8 13.5V13H12.5C13.8807 13 15 11.8807 15 10.5V7H16.5C17.3284 7 18 7.67157 18 8.5V13.5C18 14.3284 17.3284 15 16.5 15V17C16.5 17.412 16.0296 17.6472 15.7 17.4L12.5 15H9.5C8.67157 15 8 14.3284 8 13.5Z"
      fill="#B3BBCB"
    />
  </svg>
);
