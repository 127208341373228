import { Box, Icon, Skeleton, Stack, Typography } from '@mui/material';
import { SyncUser, WithAvatar, WithName } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { ContextMenu, ContextMenuAction, CrossCircleIcon, DeleteIcon } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { format } from 'date-fns';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useDeleteHandler, useMessageContext } from 'stream-chat-react';

export const Message = () => {
  const { isMyMessage: MyMessage, message } = useMessageContext();
  const handleDelete = useDeleteHandler(message);
  const isMyMessage = MyMessage();

  const isDeleted = message.type === 'deleted';

  const actions: ContextMenuAction[] =
    isMyMessage && !isDeleted
      ? [
          {
            labelTextId: 'action-Delete',
            color: 'error.main',
            icon: <DeleteIcon />,
            onClick: async (close, e) => {
              e && (await handleDelete(e));
              close?.();
            },
          },
        ]
      : [];

  if (!message.user) {
    return null;
  }

  return (
    <Stack data-message-id={message.id} mb={2}>
      <ContextMenu
        placement="bottom-end"
        PopperProps={{ disablePortal: false }}
        arrow={false}
        componentsProps={{
          tooltip: {
            sx: {
              minWidth: 215,
              '&&.MuiTooltip-tooltip': {
                mt: 0.25,
              },
              '.container': {
                gap: 1.5,
                paddingX: 0,
              },
            },
          },
        }}
        actions={actions}
      >
        {(open) => (
          <Stack
            onContextMenu={(e) => {
              if (!actions.length) return;
              e.preventDefault();
              open();
            }}
          >
            <MessageContent
              isMyMessage={isMyMessage}
              title={
                <MessageTitle
                  isMyMessage={isMyMessage}
                  userName={
                    isMyMessage ? 'You' : getUserFullName(message.user as unknown as SyncUser)
                  }
                  messageTime={format(message.created_at as unknown as Date, 'HH:mm')}
                />
              }
              isDeleted={isDeleted}
              text={message.text ?? ''}
              user={message.user as unknown as SyncUser}
            />
          </Stack>
        )}
      </ContextMenu>
    </Stack>
  );
};

export const MessageTitle = ({
  isMyMessage,
  userName,
  messageTime,
}: {
  isMyMessage: boolean;
  userName: ReactNode;
  messageTime: ReactNode;
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {!isMyMessage ? (
        <>
          <Typography variant="h3" color="common.grey2">
            {userName}
          </Typography>
          <Typography variant="caption" color="common.grey3">
            {messageTime}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="caption" color="common.grey3">
            {messageTime}
          </Typography>
          <Typography variant="h3" color="common.grey2">
            {userName}
          </Typography>
        </>
      )}
    </Stack>
  );
};

type MessageContentProps = {
  isMyMessage?: boolean;
  title?: ReactNode;
  text?: string;
  user?: WithName & WithAvatar;
  isLoading?: boolean;
  maxWidth?: string;
  isDeleted?: boolean;
  minWidth?: string;
} & PropsWithChildren;

export const MessageContent: FC<MessageContentProps> = ({
  isMyMessage,
  title,
  children,
  text,
  user,
  isLoading,
  maxWidth = '88%',
  isDeleted = false,
  minWidth,
}) => {
  const textColor = isMyMessage ? 'primary.main' : 'common.grey2';

  const alignItems = isMyMessage ? 'flex-end' : 'flex-start';
  return (
    <Stack
      sx={{
        alignItems,
        alignSelf: alignItems,
        maxWidth,
      }}
    >
      {!isLoading ? (
        <Stack
          sx={{
            flexDirection: 'row',
            pl: 0,
            ml: !isMyMessage ? 7 : 0,
          }}
        >
          {title}
        </Stack>
      ) : null}

      <Stack
        direction="row"
        gap={1.5}
        sx={{
          alignSelf: alignItems,
        }}
      >
        {!isMyMessage && (
          <Box>
            {isLoading ? (
              <Skeleton
                variant="circular"
                width={44}
                height={44}
                sx={{
                  visibility: user ? 'visible' : 'hidden',
                }}
              />
            ) : !!user ? (
              <AvatarAuth user={user} sx={{ width: 44, height: 44 }} />
            ) : null}
          </Box>
        )}

        <Stack
          sx={(theme) => ({
            backgroundColor: isMyMessage ? 'common.grey7' : theme.palette.background.paper,
            borderRadius: theme.spacing(2),
            p: 1,
            height: '100%',
            wordBreak: 'break-word',
            minWidth,
          })}
        >
          {isLoading ? (
            <Stack spacing={0.5} p={1}>
              <Skeleton variant="rectangular" width="90%" height={12} />
              <Skeleton variant="rectangular" width="76%" height={12} />
            </Stack>
          ) : (
            <Typography
              variant={isDeleted ? 'body2' : 'h3'}
              color={isDeleted ? 'common.grey' : textColor}
            >
              {isDeleted ? (
                <Stack direction="row" gap={0.5} alignItems="center">
                  <Icon fontSize="small" sx={{ color: 'common.grey', pr: 0.5 }}>
                    <CrossCircleIcon />
                  </Icon>
                  Message deleted
                </Stack>
              ) : (
                text
              )}
            </Typography>
          )}
        </Stack>

        {children}
      </Stack>
    </Stack>
  );
};

type MessageActionsProps = {
  isMyMessage?: boolean;
  isMessageRead?: boolean;
} & PropsWithChildren;

export const MessageActions: FC<MessageActionsProps> = ({
  isMyMessage = true,
  isMessageRead = true,
  children,
}) => {
  return (
    <Stack
      direction="row"
      gap={2}
      position="relative"
      justifyContent="space-between"
      px={1}
      sx={{
        minHeight: '40px',
        '.str-chat__message-reaction': {
          height: '24px',
          mt: 1,
        },
      }}
    >
      {children}
      <Stack direction="row" gap={2}>
        {isMyMessage && (
          <Box mt={1.5}>
            <Icon sx={{ color: isMessageRead ? 'success.main' : 'common.grey' }}>
              {/* <ReadIcon /> */}
            </Icon>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
