import { StreamChannel } from '@schooly/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Channel, DefaultGenerics, FormatMessageResponse, StreamChat } from 'stream-chat';

import { CHANNEL_TYPE } from './constants';

export type ChannelReadState = {
  lastReadMessageId: string | null;
  unreadCount: number;
  messageCount: number;
  lastMessage: FormatMessageResponse<DefaultGenerics> | null;
};

type UseChannelReadStateProps = {
  client: StreamChat;
  threadChannel: StreamChannel;
};

export const useChannelReadState = ({ client, threadChannel }: UseChannelReadStateProps) => {
  const [channel, setChannel] = useState<Channel | null>(null);
  const [readState, setReadState] = useState<ChannelReadState>({
    lastReadMessageId: threadChannel.last_read_message_id,
    unreadCount: threadChannel.unread_count,
    messageCount: threadChannel.message_count,
    lastMessage: threadChannel.last_message,
  });

  const userId = client.user?.id;

  const canSubscribe = useMemo(() => {
    return threadChannel.members.some(
      (m) => m.user_id === userId && m.channel_role !== 'channel_spectator',
    );
  }, [threadChannel.members, userId]);

  const updateReadState = useCallback(
    async (channelInstance: Channel) => {
      if (!userId) return;
      const userReadState = channelInstance.state.read[userId];

      if (!userReadState) return;

      const messages = channelInstance.state.messageSets[0].messages ?? [];
      const lastMessage = [...messages].reverse().find((m) => m.user?.id === userId) ?? null;

      setReadState({
        lastReadMessageId: userReadState.last_read_message_id ?? null,
        unreadCount: userReadState.unread_messages ?? 0,
        messageCount: messages.length,
        lastMessage,
      });
    },
    [userId],
  );

  useEffect(() => {
    if (!client.user?.id) return;
    const initChannel = async () => {
      try {
        const channelInstance = client.channel(CHANNEL_TYPE, threadChannel.id);
        await channelInstance.watch();
        setChannel(channelInstance);
        updateReadState(channelInstance);
      } catch (err) {
        console.error('Error initializing channel:', err);
      }
    };
    initChannel();

    return () => {
      channel?.stopWatching();
    };
  }, [client, updateReadState, channel, canSubscribe]);

  useEffect(() => {
    if (!channel) return;

    const handleEvent = (event: any) => {
      if (event.channel_id !== channel.id) return;
      updateReadState(channel);
    };

    const subscription = channel.on(handleEvent);

    return () => {
      subscription.unsubscribe();
    };
  }, [channel, updateReadState]);

  return {
    readState,
    channel,
  };
};
