import { FormControlLabel, Icon, Skeleton, Stack, Switch, Typography } from '@mui/material';
import { StreamChannel, Thread } from '@schooly/api';
import { AvatarAuthMultiple } from '@schooly/components/avatar-auth';
import { SimpleCard, ThreadIcon, TruncatedText } from '@schooly/style';
import { Counter } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { StreamChat } from 'stream-chat';

import { useThreads } from './ThreadContext';
import { useChannelReadState } from './useChannelReadState';
import { formatChannelDate } from './utils';

type ThreadCardActionsProps = {
  unreadCount?: number;
  messageCount?: number;
  isMessageRead?: boolean;
  lastMessage?: {
    date: string;
    isRead: boolean;
  };
};

const ThreadCardActions: FC<ThreadCardActionsProps> = ({
  unreadCount,
  messageCount,
  lastMessage,
}) => {
  const count = unreadCount ? (
    <Counter sx={{ minWidth: 20, textAlign: 'center', fontWeight: 600, bgcolor: 'success.main' }}>
      {unreadCount}
    </Counter>
  ) : (
    <Stack direction="row" gap={0.25}>
      <Icon inverse sx={{ color: 'common.grey' }}>
        <ThreadIcon />
      </Icon>
      <Typography color="common.grey">{messageCount}</Typography>
    </Stack>
  );

  return (
    <Stack alignItems="flex-end" gap={0.25} pt={1.5}>
      <Stack direction="row" height={12}>
        {lastMessage ? (
          <Stack direction="row" alignItems="center" gap={0.25}>
            {/* Not implemented */}
            {/* <Icon sx={{ color: 'success.main', width: 12, height: 12 }}>
              {lastMessage.isRead ? <DoubleCheckIcon /> : <DoneIcon />}
            </Icon> */}
            <Typography className="last-message-date" variant="caption">
              {lastMessage.date}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
      <Stack direction="row" gap={1} height={20}>
        {!!messageCount ? count : null}
      </Stack>
    </Stack>
  );
};

type ThreadSkeletonRowsProps = {
  rowCount: number;
};

export const ThreadSkeletonRows: FC<ThreadSkeletonRowsProps> = ({ rowCount }) => {
  return (
    <Stack>
      {[...new Array(rowCount)].map((_, i) => {
        return (
          <SimpleCard
            key={i}
            sx={{ width: 284 }}
            loadingState={{
              avatarSize: 58,
              skeletons: [
                <Skeleton variant="rectangular" width={94} height={12} />,
                <Skeleton variant="rectangular" width={160} height={12} />,
              ],
            }}
          />
        );
      })}
    </Stack>
  );
};

type ThreadItemItemProps = {
  thread: Thread;
  onClick?: () => void;
};

export const ThreadItem: FC<ThreadItemItemProps> = ({ thread, onClick }) => {
  const { client, selectedThread } = useThreads();

  if (!client) throw new Error('Client was not provided');

  if (!thread.channel)
    return (
      <ThreadCard thread={thread} selected={thread.id === selectedThread?.id} onClick={onClick} />
    );

  return (
    <ThreadCardWithChannel
      client={client}
      thread={thread}
      threadChannel={thread.channel}
      onClick={onClick}
      selected={thread.id === selectedThread?.id}
    />
  );
};
type ThreadCardWithChannelProps = {
  thread: Thread;
  threadChannel: StreamChannel;
  onClick?: () => void;
  client: StreamChat;
  selected?: boolean;
};

const ThreadCardWithChannel: FC<ThreadCardWithChannelProps> = ({
  thread,
  threadChannel,
  onClick,
  client,
  selected,
}) => {
  const { readState } = useChannelReadState({ client, threadChannel });

  return (
    <ThreadCard
      onClick={onClick}
      thread={thread}
      selected={selected}
      unreadCount={readState.unreadCount}
      messageCount={readState.messageCount}
      lastMessage={
        readState.lastMessage
          ? {
              date: formatChannelDate(new Date(readState.lastMessage.created_at)),
              isRead: readState.lastMessage.status === 'received',
            }
          : undefined
      }
    />
  );
};

type ThreadCardProps = {
  thread: Thread;
  onClick?: () => void;
  selected?: boolean;
} & ThreadCardActionsProps;

const ThreadCard: FC<ThreadCardProps> = ({
  thread,
  unreadCount,
  messageCount,
  lastMessage,
  selected,
  onClick,
}) => {
  return (
    <SimpleCard
      onClick={onClick}
      avatar={<AvatarAuthMultiple users={thread.children} />}
      actions={
        <ThreadCardActions
          unreadCount={unreadCount}
          messageCount={messageCount}
          lastMessage={lastMessage}
        />
      }
      sx={{
        '.MuiCardActionArea-focusHighlight': {
          background: 'transparent',
        },
        '.title ': {
          color: selected ? 'primary.main' : 'common.grey2',
        },
        '.sub-title, .last-message-date': {
          color: selected ? 'common.grey2' : 'common.grey',
        },
        backgroundColor: selected ? 'common.lightBg' : 'inherit',
        '&:hover': {
          backgroundColor: selected ? 'common.light2' : 'inherit',
          '.title': {
            color: 'primary.main',
          },
          '.sub-title, .last-message-date': {
            color: 'common.grey2',
          },
        },
      }}
    >
      <Stack width={140}>
        <TruncatedText
          items={thread.children.map((c) => getUserFullName(c))}
          maxLines={1}
          typographyProps={{
            className: 'title',
            variant: 'h3',
          }}
        />
        <TruncatedText
          items={thread.parents.map((p) => getUserFullName(p))}
          maxLines={2}
          typographyProps={{ className: 'sub-title' }}
        />
      </Stack>
    </SimpleCard>
  );
};

type ThreadsUnreadProps = {
  onToggleUnread?: () => void;
  showUnread?: boolean;
  isLoading?: boolean;
};

export const ThreadsUnread: FC<ThreadsUnreadProps> = ({
  onToggleUnread,
  showUnread,
  isLoading,
}) => {
  return (
    <Stack alignItems="flex-end" py={1.5} pr={1}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={94} height={16} />
      ) : (
        <FormControlLabel
          control={<Switch checked={showUnread} onChange={onToggleUnread} />}
          label={<FormattedMessage id="messages-ThreadsUnreads" />}
          sx={{
            m: 0,
            color: showUnread ? 'text.primary' : 'text.secondary',
          }}
        />
      )}
    </Stack>
  );
};
