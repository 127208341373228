import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ModalSidebarItem } from '../../../components/ui/ModalSidebar';
import { useMessage } from '../../../context/messages/useMessage';
import useAppLocation from '../../../hooks/useAppLocation';
import useQueryStringParams from '../../../hooks/useQueryStringParams';

export enum MessageModalPreviewMode {
  MESSAGE_INFO = 'message_info',
  CONSENT_FORM = 'consent_form',
  READING_STATISTICS = 'reading_statistics',
  THREADS = 'threads',
}

export const MessagePreviewModalTabs: FC = () => {
  const { tab } = useQueryStringParams();
  const { isPublished, linkedConsentForm, hasThreads, hasAccessToThreads } = useMessage();
  const location = useAppLocation();
  const { formatMessage } = useIntl();

  const sidebarItems = useMemo<ModalSidebarItem<MessageModalPreviewMode>[]>(() => {
    const items = [
      {
        id: MessageModalPreviewMode.MESSAGE_INFO,
        title: 'messages-MessageInfo',
      },
    ];

    if (isPublished) {
      items.push({
        id: MessageModalPreviewMode.READING_STATISTICS,
        title: 'messages-ReadingStatistics',
      });
    }
    if (isPublished && hasThreads && hasAccessToThreads) {
      items.unshift({
        id: MessageModalPreviewMode.THREADS,
        title: 'messages-Threads',
      });
    }

    if (linkedConsentForm) {
      items.push({
        id: MessageModalPreviewMode.CONSENT_FORM,
        title: 'consentForms-title',
      });
    }

    return items;
  }, [isPublished, hasThreads, hasAccessToThreads, linkedConsentForm]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" pt={1.75} pb={0.5}>
      <Tabs
        value={tab}
        sx={{
          minHeight: 0,
          '.MuiTab-root:not(.Mui-selected)': {
            '& .counter': {
              backgroundColor: 'common.grey2',
            },
          },
          '& .MuiTab-root': {
            minHeight: 0,
            '& .text': {
              color: 'common.main3',
            },
            '&.Mui-selected .text': { color: 'primary.main' },
          },
        }}
      >
        {sidebarItems.map((item) => (
          <Tab
            key={item.id}
            value={item.id}
            label={
              <Link to={{ search: `?tab=${item.id}` }} replace key={item.id} state={location.state}>
                <Typography className="text" variant="h3">
                  {formatMessage({ id: item.title })}
                </Typography>
              </Link>
            }
          />
        ))}
      </Tabs>
    </Stack>
  );
};
