import {
  AvailableCriteria,
  ConsentFormShort,
  GetMessageResponse,
  IListGroupArguments,
  IncomingAttachment,
  MessageInteractor,
  MessageStatus,
  MessageUpdate,
  Recipient,
  SimpleListResult,
} from '@schooly/api';
import { Genders } from '@schooly/constants';
import { createContext, Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';

import { CreateConsentForm } from '../../components/common/ConsentForms/ConsentForm';
import { AttachmentFile } from '../../components/ui/Input/RichText/Attachments/attachment-types';
import Nationalities from '../../constants/nationalities';

export interface MessageFormCriteria {
  status?: string[];
  department?: string[];
  age_group?: string[];
  house?: string[];
  gender?: Genders[];
  nationality?: Nationalities[];
  group?: string[];
  subject?: string[];
  date?: string[];
}
export interface MessageForm
  extends Omit<
    MessageUpdate,
    | 'staff_of_students_ids'
    | 'parents_ids'
    | 'parents_of_students_criteria'
    | 'school_id'
    | 'staff_criteria'
    | 'parents_of_students_ids'
    | 'staff_ids'
    | 'attachments'
  > {
  parents_of_students_criteria: MessageFormCriteria;
  staff_criteria: MessageFormCriteria;
  parents_of_students_ids: string[];
  staff_ids: string[];
  attachments?: AttachmentFile[] | IncomingAttachment[];
}

export interface SaveMessageParams {
  formData: MessageForm;
  concentFormData?: CreateConsentForm;
}

export interface MessageContextProps {
  messageId?: string;

  fetching: boolean;
  fetchingAttachments: Record<string, boolean>;

  form: UseFormReturn<MessageForm>;

  canView: boolean;
  canEdit: boolean;
  canPublish: boolean;
  canSubmit: boolean;
  canUnsubmit: boolean;

  isPublished: boolean;
  isDraft: boolean;
  isSubmitted: boolean;
  isCreator: boolean;

  isPublishInProgress: boolean;
  isSubmitInProgress: boolean;
  isUnsubmitInProgress: boolean;
  saving: boolean;
  deleting: boolean;

  isRemoveGroupDialogOpen: boolean;
  deletingConfirmation: boolean;

  notActualGroups: string[];

  messageData?: GetMessageResponse;

  title: string;
  body: string;
  status?: MessageStatus;
  creator?: MessageInteractor;
  publisher?: MessageInteractor;
  staffCriteria: AvailableCriteria[];
  parentsCriteria: AvailableCriteria[];
  staff: SimpleListResult[];
  parentsByIndividualStudent: SimpleListResult[];
  actualParents: Recipient[];
  actualStaff: Recipient[];
  staffByIndividualStudent: SimpleListResult[];
  parents: SimpleListResult[];
  criteriaDate?: string;
  prevCriteriaDate?: string;
  linkedConsentForm?: ConsentFormShort;
  hasThreads: boolean;
  hasAccessToThreads: boolean;
  attachments: AttachmentFile[] | IncomingAttachment[];
  publishDate?: Date;

  consentForm?: UseFormReturn<CreateConsentForm, any>;
  consentFormShowed: boolean;
  showDeprecatedRecipientsWarning: boolean;

  messageFormFields?: MessageForm;
  previousCriteriaDate?: string;

  isCheckGroupsInProgress?: boolean;
  allGroupsInvalid?: boolean;
  submitAction: () => Promise<void>;
  isCloseModalDialogOpen: boolean;

  actions: {
    fetchAttachment: (attachmentId?: string) => void;
    handleClose: () => void;
    handlePreview: (id: string) => void;
    saveMessage: ({ formData, concentFormData }: SaveMessageParams) => Promise<string | undefined>;
    saveAndPublishMessage: ({
      formData,
      concentFormData,
    }: SaveMessageParams) => Promise<string | undefined>;
    saveAndSubmitMessage: ({
      formData,
      concentFormData,
    }: SaveMessageParams) => Promise<string | undefined>;
    deleteMessage: () => void;
    setDeletingConfirmation: (deletingConfirmation: boolean) => void;
    cancelDeletingConfirmation: () => void;
    publishMessage: () => Promise<void>;
    submitMessage: () => Promise<void>;
    unsubmitMessage: () => Promise<void>;
    handleConfirmRemoveGroupDialog: (cb?: () => Promise<void>) => Promise<void>;
    handleCloseRemoveGroupDialog: () => void;
    showConsentForm: () => void;
    hideConsentForm: () => void;
    onClose: () => void;
    updatePrevCriteriaDate: (date: string) => void;
    checkInvalidGroups: (params: IListGroupArguments, previewMode?: boolean) => Promise<void>;
    handleEditRemoveGroupDialog: () => void;
    showRemoveGroupDialog: () => void;
    setSubmitAction: Dispatch<SetStateAction<() => Promise<void>>>;
    showCloseModalDialog: () => void;
    hideCloseModalDialog: () => void;
    onCloseCreateModal: () => void;
    setNotActualGroups: Dispatch<SetStateAction<string[]>>;
  };
}

export const CONTEXT_NAME = 'MessageContext';

export const MessageContext = createContext<MessageContextProps>({
  form: {} as UseFormReturn<MessageForm>,

  messageId: undefined,

  fetching: false,
  fetchingAttachments: {},

  canView: false,
  canEdit: false,
  canPublish: false,
  canSubmit: false,
  canUnsubmit: false,
  hasThreads: false,
  hasAccessToThreads: false,

  isPublished: false,
  isDraft: false,
  isSubmitted: false,
  isCreator: false,

  isPublishInProgress: false,
  isSubmitInProgress: false,
  isUnsubmitInProgress: false,
  saving: false,
  deleting: false,

  isRemoveGroupDialogOpen: false,
  deletingConfirmation: false,

  notActualGroups: [],

  title: '',
  body: '',
  status: undefined,
  creator: undefined,
  publisher: undefined,
  criteriaDate: undefined,
  prevCriteriaDate: undefined,
  parents: [],
  parentsCriteria: [],
  parentsByIndividualStudent: [],
  staff: [],
  staffCriteria: [],
  staffByIndividualStudent: [],
  actualParents: [],
  actualStaff: [],
  attachments: [],

  consentFormShowed: false,

  showDeprecatedRecipientsWarning: false,

  messageFormFields: undefined,
  previousCriteriaDate: undefined,

  isCheckGroupsInProgress: false,
  allGroupsInvalid: false,
  submitAction: async () => {},
  isCloseModalDialogOpen: false,

  actions: {
    fetchAttachment: () => {},
    handleClose: () => {},
    handlePreview: () => {},
    saveMessage: async () => undefined,
    deleteMessage: () => {},
    setDeletingConfirmation: () => {},
    cancelDeletingConfirmation: () => {},
    publishMessage: async () => {},
    submitMessage: async () => {},
    unsubmitMessage: async () => {},
    handleConfirmRemoveGroupDialog: async () => {},
    handleCloseRemoveGroupDialog: () => {},
    showConsentForm: () => {},
    hideConsentForm: () => {},
    onClose: () => {},
    saveAndPublishMessage: async () => undefined,
    saveAndSubmitMessage: async () => undefined,
    updatePrevCriteriaDate: () => {},
    checkInvalidGroups: async () => undefined,
    handleEditRemoveGroupDialog: () => {},
    showRemoveGroupDialog: () => {},
    setSubmitAction: () => {},
    showCloseModalDialog: () => {},
    hideCloseModalDialog: () => {},
    onCloseCreateModal: () => {},
    setNotActualGroups: () => {},
  },
});

MessageContext.displayName = CONTEXT_NAME;
